import { EntityType } from '../../enums/entityType';
import { BuiltinIntegrationSpec, IntegrationCategory } from '../../interfaces/integrationSpec';

import airflowSvg from '../../public/images/logos/integrations/airflow.svg';
import amplitudeSvg from '../../public/images/logos/integrations/amplitude.svg';
import bigquerySvg from '../../public/images/logos/integrations/bigquery.svg';
import censusSvg from '../../public/images/logos/integrations/census.svg';
import cluvioSvg from '../../public/images/logos/integrations/cluvio.svg';
import coalesceSvg from '../../public/images/logos/integrations/coalesce.svg';
import confluenceSvg from '../../public/images/logos/integrations/confluence.svg';
import confluentKafkaSvg from '../../public/images/logos/integrations/confluent_kafka.svg';
import cosmosSvg from '../../public/images/logos/integrations/cosmos.svg';
import customSvg from '../../public/images/logos/integrations/custom.svg';
import cyeraSvg from '../../public/images/logos/integrations/cyera.svg';
import dagsterSvg from '../../public/images/logos/integrations/dagster.svg';
import databricksSvg from '../../public/images/logos/integrations/databricks.svg';
import dataplexSvg from '../../public/images/logos/integrations/dataplex.svg';
import dbtSvg from '../../public/images/logos/integrations/dbt.svg';
import fivetranSvg from '../../public/images/logos/integrations/fivetran.svg';
import gcsSvg from '../../public/images/logos/integrations/gcs.svg';
import gitSvg from '../../public/images/logos/integrations/git.svg';
import githubSvg from '../../public/images/logos/integrations/github.svg';
import glueSvg from '../../public/images/logos/integrations/glue.svg';
import greatExpectationsSvg from '../../public/images/logos/integrations/great_expectations.svg';
import hashboardSvg from '../../public/images/logos/integrations/hashboard.svg';
import hexSvg from '../../public/images/logos/integrations/hex.svg';
import hightouchSvg from '../../public/images/logos/integrations/hightouch.svg';
import hiveSvg from '../../public/images/logos/integrations/hive.svg';
import jiraSvg from '../../public/images/logos/integrations/jira.svg';
import kafkaSvg from '../../public/images/logos/integrations/kafka.svg';
import lightdashSvg from '../../public/images/logos/integrations/lightdash.svg';
import lookerSvg from '../../public/images/logos/integrations/looker.svg';
import lookerStudioSvg from '../../public/images/logos/integrations/looker_studio.svg';
import metabaseSvg from '../../public/images/logos/integrations/metabase.svg';
import mixpanelSvg from '../../public/images/logos/integrations/mixpanel.svg';
import modeSvg from '../../public/images/logos/integrations/mode.svg';
import mongodbSvg from '../../public/images/logos/integrations/mongodb.svg';
import monteCarloSvg from '../../public/images/logos/integrations/monte_carlo.svg';
import motherduckSvg from '../../public/images/logos/integrations/motherduck.svg';
import msTeamsSvg from '../../public/images/logos/integrations/ms_teams.svg';
import mysqlSvg from '../../public/images/logos/integrations/mysql.svg';
import oracleSvg from '../../public/images/logos/integrations/oracle.svg';
import pagerdutySvg from '../../public/images/logos/integrations/pagerduty.svg';
import polytomicSvg from '../../public/images/logos/integrations/polytomic.svg';
import postgresSvg from '../../public/images/logos/integrations/postgres.svg';
import powerbiSvg from '../../public/images/logos/integrations/powerbi.svg';
import presetSvg from '../../public/images/logos/integrations/preset.svg';
import quicksightSvg from '../../public/images/logos/integrations/quicksight.svg';
import redashSvg from '../../public/images/logos/integrations/redash.svg';
import redshiftSvg from '../../public/images/logos/integrations/redshift.svg';
import retoolSvg from '../../public/images/logos/integrations/retool.svg';
import s3Svg from '../../public/images/logos/integrations/s3.svg';
import salesforceSvg from '../../public/images/logos/integrations/salesforce.svg';
import sigmaSvg from '../../public/images/logos/integrations/sigma.svg';
import singlestoreSvg from '../../public/images/logos/integrations/singlestore.svg';
import slackSvg from '../../public/images/logos/integrations/slack.svg';
import snowflakeSvg from '../../public/images/logos/integrations/snowflake.svg';
import sqlserverSvg from '../../public/images/logos/integrations/sqlserver.svg';
import stitchSvg from '../../public/images/logos/integrations/stitch.svg';
import supersetSvg from '../../public/images/logos/integrations/superset.svg';
import synapseSvg from '../../public/images/logos/integrations/synapse.svg';
import tableauSvg from '../../public/images/logos/integrations/tableau.svg';
import thoughtspotSvg from '../../public/images/logos/integrations/thoughtspot.svg';
import trinoSvg from '../../public/images/logos/integrations/trino.svg';

import { DOCS_INTEGRATION_BASE } from './integrations.constants';

export const integrationList: BuiltinIntegrationSpec[] = [
	{
		name: 'Snowflake',
		type: 'snowflake',
		group: 'Data warehouses',
		description:
			'Connect your data catalog to Snowflake to enable simple data discovery across all of your data with the near-infinite scalability.',
		src: snowflakeSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/snowflake-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-storage/snowflake-integration/snowflake.md',
		categories: [IntegrationCategory.WAREHOUSE],
		resource_type: EntityType.table,
		entity_type: EntityType.table,
		queryBlockSupport: true,
		previewSupport: true,
		schemaSupport: true,
		supportPushMetadata: true,
		testable: true,
	},
	{
		name: 'BigQuery',
		type: 'bigquery',
		group: 'Data warehouses',
		description:
			'Connect BigQuery to Secoda to find your tables and metadata easily. You can use Secoda to understand how BigQuery tables connect to the rest of your data stack.',
		src: bigquerySvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/bigquery-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-storage/bigquery-integration/big-query.md',
		categories: [IntegrationCategory.WAREHOUSE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		supportPushMetadata: true,
		testable: true,
		groupSettings: {
			OAuth: {
				isOAuth: true,
				testable: false,
			},
			Credentials: {
				testable: true,
			},
		},
	},
	{
		name: 'Dataplex',
		type: 'dataplex',
		group: 'Data quality',
		description:
			'Connect Dataplex to Secoda to sync your tag templates and catalog metadata.',
		src: dataplexSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-quality-tools/dataplex`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-quality-tools/dataplex/README.md',
		categories: [IntegrationCategory.TEST],
		testable: true,
	},
	{
		name: 'Postgres',
		type: 'postgres',
		group: 'Databases',
		description:
			'Connect Postgres to Secoda to find your queries and tables easily. You can use Secoda to understand how Postgres connects to the rest of your data stack.',
		src: postgresSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/postgres-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/postgres-integration/README.md',

		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		supportPushMetadata: true,
		testable: true,
	},
	{
		name: 'Redshift',
		type: 'redshift',
		group: 'Data warehouses',
		description:
			'Connect Redshift to Secoda to find your tables and metadata easily. You can use Secoda to understand how Redshift tables connect to the rest of your data stack.',
		src: redshiftSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/redshift-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-storage/redshift-integration/redshift.md',
		categories: [IntegrationCategory.WAREHOUSE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		supportPushMetadata: true,
		testable: true,
	},
	{
		name: 'MySQL',
		type: 'mysql',
		group: 'Databases',
		description:
			'Connect MySQL to Secoda to find your queries and tables easily. You can use Secoda to understand how MySQL connects to the rest of your data stack.',
		src: mysqlSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/mysql-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/mysql-integration/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Microsoft SQL Server',
		type: 'mssql',
		group: 'Databases',
		description:
			'Use Secoda to understand how Microsoft SQL Server tables connect to the rest of your data stack.',
		src: sqlserverSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/microsoft-sql-server`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/microsoft-sql-server/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Oracle',
		type: 'oracle',
		group: 'Databases',
		description:
			'Connect Oracle to Secoda to easily search for all your information in Secoda.',
		src: oracleSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/oracle-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/oracle-integration/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'MotherDuck',
		type: 'motherduck',
		group: 'Databases',
		description:
			'Connect MotherDuck to Secoda to easily search for all your tables and columns in Secoda.',
		src: motherduckSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/motherduck`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-warehouses/motherduck/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
		isBeta: true,
	},
	{
		name: 'Glue',
		type: 'glue',
		group: 'Data pipeline',
		description:
			'Connect Glue to Secoda to easily search for all your Glue information in Secoda.',
		src: glueSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/aws-glue-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/aws-glue-integration/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Trino',
		type: 'trino',
		group: 'Databases',
		description:
			'Connect Trino to Secoda to find your queries and tables easily. You can use Secoda to understand how Trino connects to the rest of your data stack.',
		src: trinoSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}query-engines/trino`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/query-engines/trino/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: true,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Tableau',
		type: 'tableau',
		group: 'Data visualization',
		description:
			'Connect Tableau to your data catalog to see dependencies, tables and owners. Tableau provides limitless data exploration without interrupting your flow of analysis.',
		src: tableauSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/tableau-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/tableau-integration/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'Looker',
		type: 'looker',
		group: 'Data visualization',
		description:
			'Easily search for all your Looker information in Secoda. The Looker integration will pull information related to Looks and put them into their own page on the Secoda UI.',
		src: lookerSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/looker-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/looker-integration/README.md',
		encrypted_fields: ['client_id', 'client_secret'],
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'Power BI',
		type: 'powerbi',
		group: 'Data visualization',
		description:
			'The Power BI integration will pull information related to your reports and put them into their own page on the Secoda UI.',
		src: powerbiSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/power-bi`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/power-bi/README.md',
		groupSettings: {
			OAuth: {
				isOAuth: true,
				testable: false,
			},
			Managed: {
				testable: true,
			},
		},
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		testable: false,
	},
	{
		name: 'QuickSight',
		type: 'quicksight',
		group: 'Data visualization',
		description:
			'Connect Quicksight to Secoda to easily search for all your dashboards information in Secoda.',
		src: quicksightSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/quicksight-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/quicksight-integration/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		previewSupport: true,
		queryBlockSupport: false,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Databricks',
		type: 'databricks',
		group: 'Data warehouses',
		description:
			'Connect your data catalog to Databricks to enable simple data discovery across all of your data with the near-infinite scalability.',
		previewSupport: true,
		src: databricksSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/databricks-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-storage/databricks-integration.md',

		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		queryBlockSupport: true,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Apache Hive',
		type: 'hive',
		group: 'Data warehouses',
		description:
			'Connect Secoda to Hive to find your tables and metadata easily. You can use Secoda to understand how Hive tables connect to the rest of your data stack.',
		previewSupport: true,
		src: hiveSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/apache-hive`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-warehouses/apache-hive/README.md',

		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Mode',
		type: 'mode',
		group: 'Data visualization',
		description:
			'Connect Mode to Secoda to find your queries, dashboards and visualizations easily. You can use Secoda to understand how Mode connects to the rest of your data stack.',
		src: modeSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/mode`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/mode/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'Redash',
		type: 'redash',
		group: 'Data visualization',
		description:
			'Connect Redash to Secoda to find your queries, dashboards and visualizations easily. You can use Secoda to understand how Redash connects to the rest of your data stack.',
		src: redashSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/redash`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/redash/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		testable: true,
	},
	{
		name: 'Slack',
		type: 'slack',
		group: 'Productivity',
		description:
			'Connect Slack to Secoda to get notified on schema changes, outdated tables, and documentation changes.',
		src: slackSvg,
		// Can't use the base help article because it's a different format
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/slack-connection`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/slack-connection/README.md',
		oauth: {
			base_url: 'https://sp.secoda.co/initiate',
			scope:
				'chat:write chat:write.public commands incoming-webhook chat:write.customize channels:read groups:read im:read users:read users:read.email users.profile:read groups:write im:write im:history mpim:history channels:history channels:join app_mentions:read reactions:read reactions:write groups:history channels:manage',
			redirect_uri: 'https://sp.secoda.co/callback',
			response_type: 'code',
		},
		categories: [IntegrationCategory.COMMUNICATION],
		testable: false,
	},
	{
		name: 'Microsoft Teams',
		type: 'ms_teams',
		type_label: 'Microsoft Teams',
		group: 'Productivity',
		description:
			'Connect Microsoft Teams to Secoda to get notified on schema changes, outdated tables, and documentation changes.',
		src: msTeamsSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/microsoft-teams`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/microsoft-teams.md',
		categories: [IntegrationCategory.COMMUNICATION],
		testable: true,
	},
	{
		name: 'GitHub',
		type: 'github',
		group: 'Productivity',
		description:
			'Connect GitHub to Secoda to get notified of changes to your dbt models and data pipelines.',
		src: githubSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/github`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/github.md',
		oauth: {
			base_url: 'https://github-worker.secoda.workers.dev/github/initiate/',
		},
		categories: [IntegrationCategory.COMMUNICATION],
		disablePullMetadata: true,
		supportPushMetadata: true,
		testable: false,
		isBeta: true,
	},
	{
		name: 'Jira',
		type: 'jira',
		group: 'Productivity',
		description:
			'Does your team receive data requests through Jira? Seamlessly manage data request by integrating your Jira issues with Secoda’s Questions feature. See all Jira issues, comments, priority, assigned users and tags, and answer questions faster than ever.',
		src: jiraSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/jira`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/jira/README.md',
		categories: [IntegrationCategory.COMMUNICATION],
		testable: true,
	},
	{
		name: 'Metabase',
		type: 'metabase',
		group: 'Data visualization',
		description:
			'Easily search for all your Metabase information in Secoda. The Metabase integration will pull information related to dashboards and put them into their own page on the Secoda UI.',
		src: metabaseSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/metabase`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/metabase/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		supportGoogleIdentityAwareProxy: true,
		testable: true,
	},
	{
		name: 'Mixpanel',
		type: 'mixpanel',
		group: 'Data visualization',
		description:
			'Pull in boards, reports, and events from your Mixpanel projects, as well as number of views, owners, descriptions, and lineage. Supplementary documentation can be added to your boards, reports and events to provide even more context to your users.',
		src: mixpanelSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/mixpanel`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/mixpanel/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		isBeta: false,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'MongoDB',
		type: 'mongodb',
		group: 'Databases',
		description:
			'Seamlessly synchronize MongoDB Clusters, Databases, Collections, and Fields, directly into Secoda.',
		src: mongodbSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/mongodb`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/mongodb/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: false,
		previewSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Cluvio',
		type: 'cluvio',
		group: 'Data visualization',
		description:
			'Connect Cluvio to Secoda to easily search for all your dashboards information in Secoda.',
		src: cluvioSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/cluvio`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/cluvio/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		testable: true,
	},
	{
		name: 'Lightdash',
		type: 'lightdash',
		group: 'Data visualization',
		description:
			'Lightdash translates the dbt models you already know into optimized SQL required to directly interact with cloud data warehouses.',
		src: lightdashSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/lightdash`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/lightdash/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'Sigma',
		type: 'sigma',
		group: 'Data visualization',
		description:
			'Sigma translates the spreadsheet functions and formulas you already know into optimized SQL required to directly interact with cloud data warehouses.',
		src: sigmaSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/sigma-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/sigma-integration/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		testable: true,
		previewSupport: true,
	},
	{
		name: 'SQL Server Report Services',
		type: 'ssrs',
		group: 'Data visualization',
		description:
			'Connect SSRS to Secoda to easily search for all your reports information in Secoda.',
		src: sqlserverSvg,
		categories: [IntegrationCategory.DASHBOARD],
		testable: true,
		isBeta: true,
		resource_type: EntityType.dashboard,
		entity_type: EntityType.dashboard,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/sql-server-reporting-services`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/sql-server-reporting-services/README.md',
	},
	{
		name: 'Monte Carlo',
		type: 'monte_carlo',
		group: 'Data quality',
		description:
			'Connect Monte Carlo to Secoda to associate monitors with tables in Secoda',
		src: monteCarloSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-quality-tools/monte-carlo`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-quality-tools/monte-carlo/README.md',
		categories: [IntegrationCategory.TEST],
		account_id: '',
		api_key: '',
		entity_type: EntityType.test,
		resource_type: EntityType.test,
		skipSchemaSelect: true,
		testable: true,
	},
	{
		name: 'dbt Cloud',
		type: 'dbt',
		group: 'Data transformation tools',
		description:
			'Connect dbt to Secoda to associate dbt run, dbt data, dbt tests, information with a dataset in Secoda',
		account_id: '',
		api_key: '',
		src: dbtSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-transformation-tools/dbt`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-transformation-tools/dbt/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		skipSchemaSelect: true,
		testable: true,
	},
	{
		name: 'dbt Core',
		type: 'dbt_core',
		group: 'Data transformation tools',
		description:
			'Connect dbt to Secoda to associate dbt run, dbt data, dbt tests, information with a dataset in Secoda',
		src: dbtSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-transformation-tools/dbt-core`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-transformation-tools/dbt-core/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		testable: true,
	},
	{
		name: 'Coalesce',
		type: 'coalesce',
		group: 'Data transformation tools',
		description:
			'Connect Coalesce to Secoda to associate runs, jobs, and tests in Secoda',
		src: coalesceSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-transformation-tools/coalesce`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-transformation-tools/coalesce/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		skipSchemaSelect: true,
		testable: true,
	},
	{
		name: 'Airflow',
		type: 'airflow',
		group: 'Data pipeline',
		description:
			'Connect Airflow to Secoda to associate DAGS with a dataset in Secoda. The airflow integration will pull information related to Airflow DAGs and put them into their own page on the Secoda UI.',
		src: airflowSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/airflow`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/airflow/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		groupSettings: {
			Astronomer: {
				testable: true,
			},
			API: {
				testable: true,
			},
			Plugin: {
				testable: false,
			},
		},
		entity_type: EntityType.job,
		resource_type: EntityType.job,
	},
	{
		name: 'Dagster',
		type: 'dagster',
		group: 'Data pipeline',
		description:
			'Connect Dagster to Secoda to easily search for all your Dagster information in Secoda',
		src: dagsterSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/dagster`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/dagster/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		isBeta: true,
		testable: true,
	},
	{
		name: 'Confluence',
		type: 'confluence',
		group: 'Productivity',
		description:
			'Seamlessly synchronize Confluence Spaces and Pages, directly into Secoda.',
		src: confluenceSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/confluence`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/confluence/README.md',
		categories: [IntegrationCategory.COMMUNICATION],
		entity_type: EntityType.document,
		resource_type: EntityType.document,
		isBeta: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Hightouch',
		type: 'hightouch',
		group: 'Data pipeline',
		description:
			'Connect Hightouch to Secoda to document your reverse ETL in the same place as the rest of their data knowledge',
		cookie: '',
		src: hightouchSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/hightouch`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/hightouch/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		testable: true,
	},
	{
		name: 'Census',
		type: 'census',
		group: 'Data pipeline',
		description:
			'Connect Census to Secoda to document your reverse ETL in the same place as the rest of their data knowledge',
		src: censusSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/census`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/census/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		testable: true,
	},
	{
		name: 'Polytomic',
		type: 'polytomic',
		group: 'Data pipeline',
		description: 'Connect Polytomic to Secoda to document your data pipelines',
		src: polytomicSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/polytomic`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/polytomic/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		testable: true,
	},
	{
		name: 'Fivetran',
		type: 'fivetran',
		group: 'Data pipeline',
		description:
			'Connect Fivetran to Secoda to easily search for all your ETL information in Secoda.',
		src: fivetranSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/fivetran-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/fivetran-integration/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		testable: true,
	},
	{
		name: 'Stitch',
		type: 'stitch',
		group: 'Data pipeline',
		description:
			'Connect Stitch to Secoda to easily search for all your ETL information in Secoda.',
		src: stitchSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/stitch`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/stitch/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.job,
		resource_type: EntityType.job,
		isBeta: false,
		testable: true,
	},
	{
		name: 'Amplitude',
		type: 'amplitude',
		group: 'Data visualization',
		description:
			'The Amplitude integration will pull information related to events, governance, put them into their own page on the Secoda UI.',
		src: amplitudeSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/amplitude-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/amplitude-integration/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.event,
		resource_type: EntityType.event,
		testable: true,
	},
	{
		name: 'Git',
		type: 'git',
		group: 'Productivity',
		description: 'Connect Git to Secoda to document your data pipelines',
		src: gitSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}productivity-tools/git-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/productivity-tools/git-integration.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		entity_type: EntityType.event,
		resource_type: EntityType.event,
		testable: true,
	},
	{
		name: 'Looker Studio',
		type: 'looker_studio',
		group: 'Data visualization',
		description:
			'Connect Looker Studio to find your queries, dashboards and visualizations easily. Use Secoda to understand how Looker Studio connects to the rest of your data stack.',
		src: lookerStudioSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/google-data-studio`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/google-data-studio/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		groupSettings: {
			OAuth: {
				isOAuth: true,
				testable: false,
			},
			Cookie: {
				testable: true,
			},
		},
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		queryBlockSupport: false,
	},
	{
		name: 'Great Expectations',
		type: 'great_expectations',
		group: 'Data quality',
		description:
			'Get all your data testing, documentation, and profiling in Secoda.',
		src: greatExpectationsSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-quality-tools/great-expectations-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-quality/great-expectations-integration.md',
		categories: [IntegrationCategory.TEST],
		entity_type: EntityType.test,
		resource_type: EntityType.test,
		testable: true,
	},
	{
		name: 'Salesforce',
		type: 'salesforce',
		group: 'Productivity',
		description:
			'Save yourself countless hours troubleshooting data in your Salesforce environment. See all field references in your entire organization and go to market faster than ever.',
		src: salesforceSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/salesforce-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/salesforce-integration/README.md',
		groupSettings: {
			OAuth: {
				isOAuth: true,
				testable: false,
			},
			Password: {
				testable: true,
			},
		},
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: false,
		previewSupport: true,
		queryBlockSupport: true,
	},
	{
		name: 'Retool',
		type: 'retool',
		group: 'Data visualization',
		description:
			'Secoda and Retool have teamed up to offer users a powerful platform for developers to build custom web apps. Easily access various data sources and APIs to develop applications that are personalized to their needs.',
		src: retoolSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/retool-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/retool-integration/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		isBeta: false,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'Azure Synapse',
		type: 'synapse',
		group: 'Data warehouses',
		description:
			'Seamlessly synchronize Azure Synapse Tables, Views, Columns, Creation Query, Lineage, and more directly into Secoda.',
		previewSupport: true,
		src: synapseSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-warehouses/azure-synapse`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-warehouses/azure-synapse/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Azure Cosmos DB',
		type: 'cosmos',
		group: 'Databases',
		description:
			'Seamlessly synchronize Azure Cosmos DB databases and containers directly into Secoda.',
		previewSupport: false,
		src: cosmosSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/azure-cosmos-db`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/azure-cosmos-db/README.md',
		categories: [IntegrationCategory.DATABASE],
		entity_type: EntityType.table,
		resource_type: EntityType.table,
		isBeta: true,
		queryBlockSupport: false,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'ThoughtSpot',
		type: 'thoughtspot',
		group: 'Data visualization',
		description:
			'Connect ThoughtSpot to Secoda to easily search for all your analytics in Secoda.',
		src: thoughtspotSvg,
		categories: [IntegrationCategory.DASHBOARD],
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/thoughtspot`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/thoughtspot/README.md',
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		isBeta: true,
		previewSupport: true,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Superset',
		type: 'superset',
		group: 'Data visualization',
		description:
			'Connect Superset to Secoda to easily search for all your analytics in Secoda.',
		src: supersetSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/superset`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/superset/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		isBeta: true,
		previewSupport: true,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Preset',
		type: 'preset',
		group: 'Data visualization',
		description:
			'Connect Preset to Secoda to easily search for all your analytics in Secoda.',
		src: presetSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/preset`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master//integrations/data-visualization-tools/preset/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		resource_type: EntityType.dashboard,
		entity_type: EntityType.dashboard,
		isBeta: true,
		previewSupport: false,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'SingleStore',
		type: 'singlestore',
		group: 'Databases',
		description:
			'Seamlessly synchronize SingleStore databases and tables directly into Secoda',
		src: singlestoreSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}databases/singlestore`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/databases/singlestore/README.md',
		categories: [IntegrationCategory.DATABASE],
		resource_type: EntityType.table,
		entity_type: EntityType.table,
		isBeta: false,
		previewSupport: true,
		queryBlockSupport: true,
		schemaSupport: true,
		testable: true,
	},
	{
		name: 'Cyera',
		type: 'cyera',
		group: 'Data quality',
		description: 'Connect Cyera to Secoda to tag all your sensitive data',
		src: cyeraSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-quality-tools/cyera`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-quality-tools/cyera.md',
		categories: [IntegrationCategory.TEST],
		isBeta: true,
		queryBlockSupport: false,
		schemaSupport: false,
		skipSchemaSelect: true,
		testable: true,
	},
	{
		name: 'Hashboard',
		type: 'hashboard',
		group: 'Data visualization',
		description:
			'Connect Hashboard to view all metrics, dashboards, models, and saved exploration in Secoda',
		src: hashboardSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/hashboard`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/hashboard/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		resource_type: EntityType.dashboard,
		isBeta: true,
		previewSupport: true,
		queryBlockSupport: false,
		schemaSupport: false,
		testable: true,
	},
	{
		name: 'Apache Kafka',
		type: 'kafka',
		group: 'Data pipeline',
		description:
			'Connect Apache Kafka to Secoda. The kafka integration will pull information related to Kafka Topics and Consumer Groups into Secoda.',
		src: kafkaSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/apache-kafka`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/apache-kafka/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		testable: true,
		previewSupport: false,
		queryBlockSupport: false,
		isBeta: true,
		resource_type: EntityType.job,
		entity_type: EntityType.job,
	},
	{
		name: 'Confluent Cloud',
		type: 'confluent_cloud',
		group: 'Data pipeline',
		description:
			'Connect Confluent Cloud to Secoda. The kafka integration will pull information related to Kafka Topics and Consumer Groups into Secoda.',
		src: confluentKafkaSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-pipeline-tools/confluent-cloud`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-pipeline-tools/confluent-cloud/README.md',
		categories: [IntegrationCategory.ORCHESTRATOR],
		testable: true,
		previewSupport: false,
		queryBlockSupport: false,
		isBeta: true,
		resource_type: EntityType.job,
		entity_type: EntityType.job,
	},
	{
		name: 'PagerDuty',
		type: 'pagerduty',
		group: 'Productivity',
		description: 'Connect PagerDuty to Secoda to receive Monitoring Alerts',
		account_id: '',
		api_key: '',
		src: pagerdutySvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-quality-tools/pagerduty`,
		categories: [IntegrationCategory.TEST],
		testable: false,
		schemaSupport: false,
		skipSchemaSelect: true,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-quality-tools/pagerduty/README.md',
	},
	{
		name: 'Google Cloud Storage',
		type: 'gcs',
		group: 'Data storage',
		description: 'Connect Google Cloud Storage buckets to Secoda',
		src: gcsSvg,
		categories: [IntegrationCategory.STORAGE],
		testable: true,
		previewSupport: false,
		queryBlockSupport: false,
		isBeta: true,
		resource_type: EntityType.table,
		entity_type: EntityType.table,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-lakes/google-cloud-storage`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-lakes/google-cloud-storage/README.md',
	},
	{
		name: 'S3',
		type: 's3',
		group: 'Data storage',
		description: 'Connect S3 buckets to Secoda',
		src: s3Svg,
		categories: [IntegrationCategory.STORAGE],
		testable: true,
		previewSupport: false,
		queryBlockSupport: false,
		isBeta: true,
		resource_type: EntityType.table,
		entity_type: EntityType.table,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-lakes/aws-s3`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-lakes/aws-s3/README.md',
	},
	{
		name: 'Hex',
		type: 'hex',
		group: 'Data visualization',
		description: 'Connect Hex to Secoda to view Hex projects',
		src: hexSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}data-visualization-tools/hex`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/data-visualization-tools/hex/README.md',
		categories: [IntegrationCategory.DASHBOARD],
		entity_type: EntityType.dashboard,
		isBeta: true,
		resource_type: EntityType.dashboard,
		previewSupport: true,
		testable: true,
	},
	{
		name: 'File Upload',
		type: 'custom',
		group: 'Custom',
		description:
			'Connect any data source to Secoda using CSV or JSONL to easily search for all your information in Secoda.',
		src: customSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}custom-integrations-and-marketplace/custom-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/custom-integrations-and-marketplace/custom-integration/README.md',
		categories: [IntegrationCategory.CUSTOM],
		entity_type: EntityType.table,
		hidden: false,
	},
	{
		name: 'Marketplace',
		type: 'marketplace',
		group: 'Custom',
		description: 'Write your own integration for the Secoda Marketplace.',
		src: customSvg,
		helpArticle: `${DOCS_INTEGRATION_BASE}custom-integrations-and-marketplace/secoda-sdk-custom-integration`,
		documentationMarkdown:
			'https://raw.githubusercontent.com/secoda/gitbook/master/integrations/custom-integrations-and-marketplace/secoda-sdk-custom-integration/README.md',
		categories: [IntegrationCategory.CUSTOM],
		entity_type: EntityType.table,
		hidden: true,
	},
];
