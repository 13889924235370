import { MonitorSpec, MonitorType } from './types';

export const MONITOR_SPECS: MonitorSpec[] = [
	{
		type: { metricType: 'freshness' },
		iconName: 'clockPlay',
		iconColor: 'icon/decorative/sunset',
		backgroundColor: 'fill/decorative/sunset',
		title: 'Freshness',
		group: 'Table',
		description: 'Monitors the time elapsed since last update.',
	},
	{
		type: { metricType: 'row_count' },
		iconName: 'layoutRows',
		iconColor: 'icon/decorative/sunset',
		backgroundColor: 'fill/decorative/sunset',
		title: 'Row Count',
		group: 'Table',
		description: 'Monitors the number of rows over time.',
	},
	{
		type: { metricType: 'cardinality' },
		iconName: 'columns2',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Cardinality',
		group: 'Column',
		description: 'Monitors the number of distinct values in a column.',
	},
	{
		type: { metricType: 'max' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Max',
		group: 'Column',
		description: 'Monitors the maximum of all values in a column.',
	},
	{
		type: { metricType: 'mean' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Mean',
		group: 'Column',
		description: 'Monitors the average of all values in a column.',
	},
	{
		type: { metricType: 'min' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Minimum',
		group: 'Column',
		description: 'Monitors the minimum of all values in a column.',
	},
	{
		type: { metricType: 'null_percentage' },
		iconName: 'percentage',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Null Percentage',
		group: 'Column',
		description: 'Monitors the percentage of values in a column that are null.',
	},
	{
		type: { metricType: 'unique_percentage' },
		iconName: 'percentage',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Unique Percentage',
		group: 'Column',
		description:
			'Monitors the percentage of values in a column that are unique.',
	},
	{
		type: { metricType: 'custom_sql' },
		iconName: 'code',
		iconColor: 'icon/decorative/lime',
		backgroundColor: 'fill/decorative/lime',
		title: 'Custom SQL',
		group: 'Custom',
		description: 'Define a monitor using custom SQL.',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'snowflake_cost' },
		iconName: 'coin',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake cost',
		group: 'Warehouse',
		description: 'Monitors the costs incurred of a Snowflake warehouse.',
		integrationType: 'snowflake',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'snowflake_query_volume',
		},
		iconName: 'database',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake query volume',
		description:
			'Monitors the number of queries executed against a Snowflake integration.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'snowflake_database_bytes',
		},
		iconName: 'database',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake database bytes usage',
		description: 'Monitors the database byte usage of a Snowflake database.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'snowflake_compute_credits',
		},
		iconName: 'businessplan',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake compute credits',
		description: 'Monitors the compute credit usage of a Snowflake warehouse.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'snowflake_failsafe_bytes_usage',
		},
		iconName: 'businessplan',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake failsafe bytes usage',
		description: 'The failsafe byte usage of a Snowflake database.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'snowflake_cloud_services_credits',
		},
		iconName: 'businessplan',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake cloud services credits',
		description: 'The cloud service credit usage of a Snowflake warehouse.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'dbt_test_warnings' },
		iconName: 'alertTriangle',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test warnings',
		description:
			'Monitors the number of failed dbt tests across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'dbt_test_skips' },
		iconName: 'playerSkipForward',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test skips',
		description:
			'Monitors the number of successful dbt tests across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'dbt_test_errors' },
		iconName: 'exclamationMark',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test errors',
		description:
			'Monitors the number of dbt test warnings across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'dbt_test_passes' },
		iconName: 'check',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test passes',
		description: 'The number of successful dbt tests across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
	{
		type: { metricType: 'analytics', integrationMetric: 'dbt_test_failures' },
		iconName: 'x',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test failures',
		description: 'The number of failed dbt tests across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
	{
		type: {
			metricType: 'analytics',
			integrationMetric: 'dbt_test_execution_times',
		},
		iconName: 'x',
		iconColor: 'icon/decorative/orange',
		backgroundColor: 'fill/decorative/orange',
		title: 'dbt test execution time',
		description: 'The execution time of dbt tests across a table or column.',
		group: 'Tests',
		integrationType: 'dbt',
	},
];

export const getMonitorSpec = (type: MonitorType): MonitorSpec => {
	if (type.metricType === 'analytics') {
		const spec = MONITOR_SPECS.find(
			(s) =>
				s.type.metricType === 'analytics' &&
				s.type.integrationMetric === type.integrationMetric
		);
		if (!spec) {
			throw new Error(`Monitor spec not found for ${type.integrationMetric}`);
		}
		return spec;
	} else {
		const spec = MONITOR_SPECS.find(
			(s) => s.type.metricType === type.metricType
		);
		if (!spec) {
			throw new Error(`Monitor spec not found for ${type.metricType}`);
		}
		return spec;
	}
};
