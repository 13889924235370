import type { Filter } from '@repo/api-codegen';
import { Icon } from '@repo/foundations';
import { filter } from 'lodash-es';
import type { Location } from 'react-router';
import { ISecodaEntity } from '../../api';
import { fetchCatalogList } from '../../api/hooks/resourceCatalog';
import { EntityType } from '../../lib/types';
import { TreeNodeBase } from './TreeNode';

export const isActive = (href: string[], location: Location, exact = false) =>
	filter(href, (h) => {
		if (h === '/' && location.pathname === '/') {
			return true;
		}
		if (h !== '/') {
			if (exact) {
				return location.pathname === h;
			}

			return location.pathname.includes(h);
		}
		return false;
	}).length > 0;

export type CommonEntityTreeNode = TreeNodeBase & {
	entity: ISecodaEntity;
};

export const getSameEntityTypeChildren = async (
	node: CommonEntityTreeNode,
	page: number = 1
): Promise<{ results: CommonEntityTreeNode[]; nextPage?: number | null }> => {
	const { results, meta } = await fetchCatalogList({
		filters: {
			filter: {
				operator: 'and',
				operands: [
					{
						field: 'entity_type',
						operator: 'exact',
						value: node.entity.entity_type,
					},
					{
						field: 'parent_id',
						operator: 'exact',
						value: node.entity.id,
					},
				],
			} as Filter,
			page_size: 100,
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
		page,
	});

	return {
		results: results.map((entity) => ({
			id: entity.id,
			entity,
			hasChildren: Boolean(entity.has_child_of_same_type),
		})),
		nextPage: meta.next_page,
	};
};

export const getEntityIconByType = (type: EntityType) => {
	switch (type) {
		case EntityType.table:
			return <Icon name="table" color="icon/primary/default" />;
		case EntityType.column:
			return <Icon name="columns" color="icon/primary/default" />;
		case EntityType.dashboard:
			return <Icon name="presentationAnalytics" color="icon/primary/default" />;
		case EntityType.chart:
			return <Icon name="chartBar" color="icon/primary/default" />;
		case EntityType.job:
			return <Icon name="clock" color="icon/primary/default" />;
		case EntityType.collection:
			return <Icon name="folder" color="icon/primary/default" />;
		case EntityType.dictionary_term:
			return <Icon name="book" color="icon/primary/default" />;
		case EntityType.metric:
			return <Icon name="chartHistogram" color="icon/primary/default" />;
		case EntityType.document:
			return <Icon name="fileAnalytics" color="icon/primary/default" />;
		case EntityType.event:
			return <Icon name="click" color="icon/primary/default" />;
		case EntityType.question:
			return <Icon name="messageCircleQuestion" color="icon/primary/default" />;
		case EntityType.schema:
			return <Icon name="schema" color="icon/primary/default" />;
		case EntityType.database:
			return <Icon name="database" color="icon/primary/default" />;
		default:
			return <Icon name="folder" color="icon/primary/default" />;
	}
};
