import { Card, Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useSpecCardStyles } from '../../IntegrationSpecCard/IntegrationSpecCard.helpers';
import { MonitorSpec } from '../types';
import { MonitorIcon } from '../MonitorIcon/MonitorIcon';
import { WidgetData } from '../../WidgetSelector/types';

interface MonitorSpecCardProps {
	data: WidgetData<MonitorSpec>;
	onClick: VoidFunction;
}

export function MonitorSpecCard({ data, onClick }: MonitorSpecCardProps) {
	const { classes } = useSpecCardStyles();
	const { title, description, type } = data.data;

	return (
		<Card
			variant="clickable"
			radius="md"
			className={classes.card}
			onClick={onClick}
		>
			<Stack h="100%" spacing="sm" pb="sm">
				<MonitorIcon type={type} />
				<Stack spacing="xs">
					<Group spacing="xs" noWrap>
						<Text size="sm" weight="bold">
							{title}
						</Text>
					</Group>
					<Text size="xs">{description}</Text>
				</Stack>
			</Stack>
		</Card>
	);
}
