import type { DataQualityScore } from '@repo/api-codegen';
import { useGenerateDataQualityImage } from '@repo/api-codegen';
import { useCallback, useEffect, useState } from 'react';

function downloader() {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style.display = 'none';
	return {
		container: a,
		trigger: (dataUrl: string) => {
			a.href = dataUrl;
			a.download = 'quality-score.jpg';
			a.click();
		},
	};
}

function blobToDataUrl(blob: Blob): Promise<string | null | undefined> {
	return new Promise((resolve) => {
		const fileReader = new FileReader();
		fileReader.onload = (e) => resolve(e.target?.result as string);
		fileReader.readAsDataURL(blob);
	});
}

export function useDataQualityImage(data: DataQualityScore | undefined) {
	const [cachedImage, setCachedImage] = useState<Blob | null>(null);
	const { container, trigger } = downloader();
	const { mutateAsync } = useGenerateDataQualityImage({});

	useEffect(
		() => () => {
			document.body.removeChild(container);
		},
		[container]
	);

	useEffect(() => {
		setCachedImage(null);
	}, [data]);

	const downloadImage = useCallback(async () => {
		if (!data) {
			return null;
		}

		if (cachedImage) {
			return cachedImage;
		}

		const image = await mutateAsync({
			body: data,
		});

		setCachedImage(image);

		return image;
	}, [cachedImage, data, mutateAsync]);

	const copy = useCallback(async () => {
		if (!data) {
			return;
		}

		const image = await downloadImage();

		if (!image) {
			return;
		}

		navigator.clipboard.write([
			new ClipboardItem({
				[image.type]: image,
			}),
		]);
	}, [data, downloadImage]);

	const download = useCallback(async () => {
		if (!data) {
			return;
		}

		const image = await downloadImage();

		if (!image) {
			return;
		}

		const dataUrl = await blobToDataUrl(image);

		if (dataUrl) {
			trigger(dataUrl);
		}
	}, [data, downloadImage, trigger]);

	return {
		copy,
		download,
	};
}
