import { Group, Stack, useMantineTheme } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { Icon, Switch, Text } from '@repo/foundations';
import type React from 'react';
import { useState } from 'react';
import { useTeamManagement } from '../../api/hooks/team/teamsManagement';
import type { SidebarSettings } from '../../api/types/models/team';
import { getSideBarSettingValue } from '../../api/types/models/team';
import { MENU_ITEMS } from './constants';

export function TeamSidebarTab({ team }: { team: TeamOut }) {
	const theme = useMantineTheme();
	const { updateTeam } = useTeamManagement(team.id);

	const [settings, setSettings] = useState<SidebarSettings>({
		home: getSideBarSettingValue(team.sidebar_settings, 'home'),
		catalog: getSideBarSettingValue(team.sidebar_settings, 'catalog'),
		collections: getSideBarSettingValue(team.sidebar_settings, 'collections'),
		dictionary: getSideBarSettingValue(team.sidebar_settings, 'dictionary'),
		documents: getSideBarSettingValue(team.sidebar_settings, 'documents'),
		questions: getSideBarSettingValue(team.sidebar_settings, 'questions'),
		metrics: getSideBarSettingValue(team.sidebar_settings, 'metrics'),
	});

	const onChange = async (
		key: keyof SidebarSettings,
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const newSettings = {
			...settings,
			[key]: e.currentTarget.checked,
		};

		setSettings(newSettings);
		await updateTeam({
			pathParams: {
				teamId: team.id,
			},
			body: {
				sidebar_settings: newSettings,
			},
		});
	};

	return (
		<Stack p={24} spacing={4}>
			<Text size="sm" fw={500}>
				Show these items in the sidebar
			</Text>
			<Stack
				sx={{
					borderRadius: 8,
					border: `1px solid ${theme.colors.gray[4]}`,
				}}
				p={4}
				spacing={0}
			>
				{MENU_ITEMS.map((item) => (
					<Group key={item.key} position="apart" p={6}>
						<Group spacing={8}>
							<Icon name={item.iconName} />
							<Text size="sm">{item.label}</Text>
						</Group>
						<Switch
							size="md"
							checked={settings[item.key]}
							onChange={(e) => onChange(item.key, e)}
							aria-label={`Show ${item.label} on the sidebar`}
						/>
					</Group>
				))}
			</Stack>
		</Stack>
	);
}
