import {
	LiteUserOut,
	SecodaEntityNotificationHookOut,
} from '@repo/api-codegen';
import { ISecodaEntity } from '../../api';
import type {
	ISlackChannel,
	ISlackHook,
	SecodaEntity,
	UserGroup,
} from '../../lib/models';
import { User } from '../../lib/models';
import { entityUrl } from '../../utils/navigationUtils';

export type OptionType = 'user' | 'group' | 'channel';

export type MultiSelectMap<T> = (group: T) => (item: any) => {
	label: string;
	value: string;
	group: T;
	type: OptionType;
	original: any;
};

export const selectUserMap =
	<T>(group: T) =>
	(user: User | LiteUserOut) => ({
		label: new User(user).display_name.trim()
			? new User(user).display_name
			: new User(user).email,
		value: user.id,
		group,
		type: 'user',
		original: user,
	});

export const selectGroupMap =
	<T>(group: T) =>
	(groupModel: UserGroup) => ({
		label: groupModel.name,
		value: groupModel.id,
		group,
		type: 'group',
		original: groupModel,
	});

export const selectChannelMap =
	<T>(group: T) =>
	(channel: ISlackChannel) => ({
		label: channel.name,
		value: channel.id,
		group,
		type: 'channel',
		original: channel,
	});

export const selectHookMap =
	<T>(group: T) =>
	(hook: ISlackHook | SecodaEntityNotificationHookOut) => ({
		label: hook.slack_conversation_name || '',
		value: hook.slack_conversation_id || '',
		group,
		type: 'channel',
		original: hook,
	});

export const announcementTemplate = (entity: SecodaEntity | ISecodaEntity) =>
	`[Link to the resource](${window.location.origin}${entityUrl(entity)})`;
