/* eslint-disable no-console */
import { Dialog, Group, Stack } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

function ReloadPrompt() {
	const [loading, setLoading] = useState(false);

	const {
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onRegistered(r) {
			console.error('SW Registered: ' + r);
		},
		onRegisterError(error) {
			console.error('SW registration error', error);
		},
	});

	const close = useCallback(() => {
		setNeedRefresh(false);
	}, [setNeedRefresh]);

	return (
		<Dialog opened={needRefresh} onClose={close}>
			<Stack spacing="md">
				<Text size="md">
					New update available, click on reload button to update.
				</Text>
				<Group position="right">
					<Button
						loading={loading}
						disabled={loading}
						leftIconName="reload"
						variant="primary"
						onClick={() => {
							setLoading(true);
							updateServiceWorker(true);
						}}
					>
						Reload
					</Button>
				</Group>
			</Stack>
		</Dialog>
	);
}

export default ReloadPrompt;
