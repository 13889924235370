import { EditorView } from 'prosemirror-view';
import { EditorDictionary, MenuItem } from '@repo/secoda-editor';

import { Box, createStyles } from '@mantine/core';
import { useEffect } from 'react';
import { CommandFactory } from '../../lib/Extension';
import { VisuallyHidden } from '../VisuallyHidden';
import { GenericFilterMenu } from '../../../../../../pages/SearchPage/FilterCarousel/AddFilterMenu/GenericFilterMenu';
import { useMetricList as useChartBlockList } from '../../../../../../api';
import { CommandMenuDivider } from './CommandMenuDivider';
import { useCommandMenu } from './useCommandMenu';
import { useToolbarSearch } from './useToolbarSearch';
import { LinkInput } from './LinkInput';
import { useFloatingToolbarContext } from './context';
import { FloatingToolbar } from './FloatingToolbar';

const useStyles = createStyles((theme) => ({
	scroll: {
		overflow: 'hidden',
		overflowY: 'auto',
		padding: `${theme.spacing['2xs']} 0`,
	},
}));

export type CommandMenuType =
	| 'block-menu-container'
	| 'emoji-menu-container'
	| 'mention-menu-container';

export interface CommandMenuProps<T extends MenuItem = MenuItem> {
	id: CommandMenuType;
	rtl: boolean;
	commands: Record<string, CommandFactory>;
	dictionary: EditorDictionary;
	view: EditorView;
	search: string;
	loading?: boolean;
	uploadFile?: (file: File) => Promise<string>;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onShowToast?: (message: string, id: string) => void;
	onLinkToolbarOpen?: () => void;
	onPlaceholderToolbarOpen?: () => void;
	onClose: () => void;
	renderMenuItem: (
		item: T,
		index: number,
		props: Record<string, unknown>
	) => React.ReactNode;
	filterable?: boolean;
	items: T[];
	trigger?: string;
	onTrackEvent?: (
		eventName: string,
		properties?: Record<string, string>
	) => void;
}

function CommandMenuInternal({
	items,
	trigger,
	renderMenuItem,
	view,
	commands,
	uploadFile,
	search,
	filterable,
	onClose,
	onLinkToolbarOpen,
	onPlaceholderToolbarOpen,
	dictionary,
	onFileUploadStart,
	onFileUploadStop,
	onShowToast,
	onTrackEvent,
}: CommandMenuProps) {
	const { classes } = useStyles();

	const { getItemProps, activeIndex, elementsRef, reset } =
		useFloatingToolbarContext();

	const { clearSearch, filteredItems } = useToolbarSearch({
		view,
		searchTerm: search,
		trigger,
		commands,
		items,
		filterable,
		uploadEnabled: !!uploadFile,
	});

	const {
		fileUploadInputRef,
		handleFilePicked,
		handleItemClick,
		embedItem,
		insertChartItem,
		insertBlock,
		handleChartBlockPicked,
	} = useCommandMenu({
		view,
		commands,
		onClearSearch: clearSearch,
		onClose,
		onLinkToolbarOpen,
		onPlaceholderToolbarOpen,
		dictionary,
		onFileUploadStart,
		onFileUploadStop,
		uploadFile,
		onShowToast,
		onTrackEvent,
	});

	useEffect(() => {
		reset();
	}, [embedItem, insertChartItem, filteredItems, reset]);

	return (
		<>
			{embedItem && (
				<LinkInput
					placeholder={
						embedItem.title
							? dictionary.pasteLinkWithTitle(embedItem.title)
							: dictionary.pasteLink
					}
					autoFocus
					dictionary={dictionary}
					embedItem={embedItem}
					onInsertBlock={insertBlock}
					onClose={onClose}
					onShowToast={onShowToast}
				/>
			)}
			{insertChartItem && (
				<Box p={4}>
					<GenericFilterMenu
						defaultOpened
						useList={useChartBlockList}
						onClick={handleChartBlockPicked}
						useListParams={{
							filters: { hidden: false },
							options: {
								refetchOnMount: true,
								cacheTime: 10,
							},
						}}
					/>
				</Box>
			)}
			{!embedItem && !insertChartItem && (
				<Box className={classes.scroll}>
					{filteredItems.map((item, index) => {
						if (item.name === 'separator') {
							return (
								<CommandMenuDivider
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									index={index}
									title={item.title}
								/>
							);
						}

						return renderMenuItem(item, index, {
							'data-hovered': activeIndex === index ? true : undefined,
							'aria-selected': activeIndex === index ? true : undefined,
							'data-testid': `${item.name}-menu-item-${index}`,
							...getItemProps({
								active: activeIndex === index,
								role: 'option',
								id: `${item.name}-menu-item-${index}`,
								ref: (node: HTMLButtonElement) => {
									elementsRef.current[index] = node;
								},
								onClick: () => handleItemClick(item),
							}),
						});
					})}
				</Box>
			)}
			{uploadFile && (
				<VisuallyHidden>
					<label>
						<input
							aria-label="Import document"
							type="file"
							ref={fileUploadInputRef}
							onChange={handleFilePicked}
						/>
					</label>
				</VisuallyHidden>
			)}
		</>
	);
}

export function CommandMenu(props: CommandMenuProps) {
	const { onClose, id, view } = props;

	return (
		<FloatingToolbar
			onClose={onClose}
			view={view}
			placement="top-start"
			menu-id={id}
		>
			<CommandMenuInternal {...props} />
		</FloatingToolbar>
	);
}
