import type {
	DataQualityScore,
	DataQualitySuggestion,
	Filter,
} from '@repo/api-codegen';
import { capitalize, isNil } from 'lodash-es';
import { pluralize, sentenceCase } from '../../utils/stringUtils';
import {
	DATA_QUALITY_COMPLETED_ITEMS,
	DATA_QUALITY_COMPLETED_ITEMS_REPORT_LEVEL,
	DATA_QUALITY_MAX_SCORE,
	DATA_QUALITY_TOP_KEYS,
} from './constants';
import type {
	DataQualityBreakdownItem,
	DataQualityBreakdownSubItem,
} from './types';
import { DataQualityClassification } from './types';

export function formatScore(score: number) {
	/* Score should be rounded down for consistency of formatting */
	return Math.floor(score);
}

export function getScoreClassification(
	score: number | undefined
): DataQualityClassification {
	if (isNil(score) || score < 0 || score > 100) {
		return DataQualityClassification.UNAVAILABLE;
	}

	const rounded = formatScore(score || 0);
	if (rounded < 34) {
		return DataQualityClassification.POOR;
	}

	if (rounded < 67) {
		return DataQualityClassification.MODERATE;
	}

	return DataQualityClassification.GOOD;
}

export function getDataQualityClassificationCatalogFilter(
	classification: DataQualityClassification,
	field: string
): Filter | null {
	switch (classification) {
		case DataQualityClassification.GOOD: {
			return {
				operands: [],
				field,
				operator: 'gte',
				value: 67,
			};
		}
		case DataQualityClassification.MODERATE: {
			return {
				operator: 'and',
				operands: [
					{
						operands: [],
						field,
						operator: 'gte',
						value: 34,
					},
					{
						operands: [],
						field,
						operator: 'lt',
						value: 67,
					},
				],
			};
		}
		case DataQualityClassification.POOR: {
			return {
				operands: [],
				field,
				operator: 'lt',
				value: 34,
			};
		}
		default: {
			return null;
		}
	}
}

function getTopLevelDataQualityBreakdownItem(
	key: DataQualitySuggestion['data_quality_top_key'],
	dataQualityScore: DataQualityScore
): DataQualityBreakdownItem {
	const score = dataQualityScore[key];
	const maxScore = DATA_QUALITY_MAX_SCORE[key];
	const percentage = (score / maxScore) * 100;

	return {
		title: capitalize(key),
		key: key,
		score,
		maxScore,
		classification: getScoreClassification(percentage),
		percentage,
		subItems: [],
	};
}

function appendCompletedItems(
	topKey: DataQualitySuggestion['data_quality_top_key'],
	dataQualityScore: DataQualityScore,
	item: DataQualityBreakdownItem,
	page: 'resource' | 'report'
) {
	const COMPLETED_ITEMS =
		page === 'resource'
			? DATA_QUALITY_COMPLETED_ITEMS
			: DATA_QUALITY_COMPLETED_ITEMS_REPORT_LEVEL;

	const subItems = COMPLETED_ITEMS[topKey].filter(
		({ key, maxScore }) => dataQualityScore[key] === maxScore
	);

	subItems.forEach((subItem) => {
		const subScore = dataQualityScore[subItem.key as keyof DataQualityScore];
		const percentage = (subScore / subItem.maxScore) * 100;

		item.subItems.push({
			title: subItem.title,
			classification: getScoreClassification(percentage),
			percentage,
			data_quality_key: subItem.key,
			data_quality_top_key: topKey,
			isCompleted: true,
			score: subScore,
			max_score: subItem.maxScore,
		});
	});
}

export function parseDataQualitySuggestions(
	dataQualityScore: DataQualityScore,
	suggestions: DataQualitySuggestion[],
	page: 'resource' | 'report'
): DataQualityBreakdownItem[] {
	const suggestionsMap: Record<string, DataQualityBreakdownItem> = {};

	suggestions.forEach((suggestion) => {
		if (!suggestionsMap[suggestion.data_quality_top_key]) {
			suggestionsMap[suggestion.data_quality_top_key] =
				getTopLevelDataQualityBreakdownItem(
					suggestion.data_quality_top_key,
					dataQualityScore
				);
		}

		const percentage = (suggestion.score / suggestion.max_score) * 100;

		suggestionsMap[suggestion.data_quality_top_key].subItems.push({
			...suggestion,
			classification: getScoreClassification(percentage),
			percentage,
		});
	});

	DATA_QUALITY_TOP_KEYS.forEach((key) => {
		if (!suggestionsMap[key]) {
			suggestionsMap[key] = getTopLevelDataQualityBreakdownItem(
				key,
				dataQualityScore
			);
		}

		appendCompletedItems(key, dataQualityScore, suggestionsMap[key], page);
	});

	return Object.values(suggestionsMap);
}

export function getTestsAndMonitorsNoun(item: DataQualityBreakdownSubItem) {
	if (item.tests.length > 0 && item.monitors.length > 0) {
		return `${pluralize('test', item.tests.length)} and ${pluralize('monitor', item.monitors.length)}`;
	} else if (item.tests.length > 0) {
		return `${pluralize('test', item.tests.length)}`;
	} else {
		return `${pluralize('monitor', item.monitors.length)}`;
	}
}

export function getQualityKeyLabel(key: keyof DataQualityScore) {
	if (key === 'total') {
		return 'Overall score';
	}
	return sentenceCase(key);
}
