import { useApiGetTeamEmptyState } from '@repo/api-codegen';
import type { UseQueryOptionsArgs } from '../../factories/types';

export function useTeamEmptyState(
	teamId: string,
	sectionName:
		| 'catalog'
		| 'collection'
		| 'metric'
		| 'dictionary_term'
		| 'document'
		| 'question',
	options: UseQueryOptionsArgs = {}
) {
	return useApiGetTeamEmptyState(
		{
			pathParams: {
				teamId,
				sectionName,
			},
		},
		{
			...options,
		}
	);
}

export const useTeamEmptyStates = (teamId: string) => {
	const catalog = useTeamEmptyState(teamId, 'catalog');
	const collection = useTeamEmptyState(teamId, 'collection');
	const dictionaryTerm = useTeamEmptyState(teamId, 'dictionary_term');
	const metric = useTeamEmptyState(teamId, 'metric');
	const document = useTeamEmptyState(teamId, 'document');
	const question = useTeamEmptyState(teamId, 'question');

	return {
		metric: metric.data,
		catalog: catalog.data,
		collection: collection.data,
		dictionaryTerm: dictionaryTerm.data,
		document: document.data,
		question: question.data,
	};
};
