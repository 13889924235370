import type { LiteUserOut } from '@repo/api-codegen';
import { filter, includes, isNil, size } from 'lodash-es';
import type { IReport } from '../../../../api';
import type { Integration, User } from '../../../../lib/models';
import { sendChromeExtensionMessage } from '../../../../utils/extension';

export const getHasHomepageCoverImageChanged = (report?: IReport) => {
	if (report?.type === 'teams') {
		return !includes(
			report?.background_image,
			'photo-1538370965046-79c0d6907d47'
		);
	}

	return !includes(
		report?.background_image,
		'photo-1651147538420-06f5e0d3f1d9'
	);
};

export const getIsAddNewWidgetComplete = (report?: IReport) =>
	filter(report?.widgets, ({ content }) => content !== 'default').length > 0;

export const getHasHomepageWidgetReordered = (report?: IReport) =>
	!isNil(localStorage.getItem(`reorder_${report?.id}`));

export const getCustomizeHomepageComplete = (report?: IReport) =>
	getHasHomepageCoverImageChanged(report) ||
	getIsAddNewWidgetComplete(report) ||
	getHasHomepageWidgetReordered(report);

export const getInviteTeamMembersComplete = (users: User[]) => size(users) > 1;

export const getSetupTeamsComplete = (teamsCount: number | undefined) =>
	(teamsCount ?? 0) > 1;

export const getSlackIntegrationComplete = (integrations: Integration[]) =>
	Boolean(filter(integrations, ({ name }) => name === 'Slack').length);

export const getChromeExtensionAddedComplete = async () => {
	const response = await sendChromeExtensionMessage({
		type: 'check-extension',
	});
	return response.success;
};

export const getStartDocumentingComplete = (
	modifiedEntitiesCount: number | undefined
) => Boolean(modifiedEntitiesCount);

export const getConnectIntegrationComplete = (integrations: Integration[]) =>
	Boolean(filter(integrations, ({ name }) => !includes(name, 'Demo')).length);

export const getHasTeamMembersAdded = (teamMembers: LiteUserOut[]) =>
	(teamMembers.length ?? 0) > 1;

export const getExploreWorkspaceComplete = () => true;
