import { Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { TeamOut } from '@repo/api-codegen';
import { useState } from 'react';
import { InviteTeamMemberForm } from '../InviteTeamMemberForm/InviteTeamMemberForm';
import { TeamMembersList } from './TeamMembersList';

export function TeamMembersTab({ team }: { team: TeamOut }) {
	const [searchTerm, setSearchTerm] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

	return (
		<Stack p={24}>
			<InviteTeamMemberForm
				team={team}
				searchValue={searchTerm}
				setSearchValue={setSearchTerm}
				placeholder="Search for or add new team members"
			/>
			<TeamMembersList team={team} searchTerm={debouncedSearchTerm} />
		</Stack>
	);
}
