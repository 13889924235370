import { showNotification } from '@mantine/notifications';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { createMockableHook } from '../../../utils/createMockableHook';
import { apiClient, getEndpoints } from '../../common';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import { queryClient } from '../../index';
import type {
	IApiListResponse,
	IGroupItem,
	IGroupSetting,
	IIntegration,
	IUseQueryHookArgs,
} from '../../types';
import { useMarketplaceIntegrationSpec } from '../marketplace';

export const INTEGRATIONS_NAMESPACE = ['integration', 'integrations'];

export const integrationsQueryKeyFactory = createQueryKeys(
	INTEGRATIONS_NAMESPACE
);

const { prefetchIntegration, prefetchIntegrationList } =
	prefetchFunctionsFactory('integration', integrationsQueryKeyFactory);

const {
	useIntegration: useIntegrationInternal,
	useIntegrationInfiniteList,
	useIntegrationList,
	useCreateIntegration,
	useDeleteIntegration,
	useUpdateIntegration,
	fetchIntegrationList,
	updateIntegration,
} = baseQueryHooksFactory<IIntegration, 'integration'>(
	'integration',
	integrationsQueryKeyFactory
);

export const [useIntegration, MockUseIntegration] = createMockableHook(
	useIntegrationInternal
);

export {
	fetchIntegrationList,
	prefetchIntegration,
	prefetchIntegrationList,
	updateIntegration,
	useCreateIntegration,
	useDeleteIntegration,
	useIntegrationInfiniteList,
	useIntegrationList,
	useUpdateIntegration,
};

// This is a wrapper for the `useIntegrationList`. This let's us access
// integration properties without making separate API calls for each
// integration. A use case is to fetch the integration icons on the catalog page
// for a given integration id. We will assume there is only one page (50
// integrations max.).
export const useIntegrationPrefetched = (
	props: IUseQueryHookArgs<IApiListResponse<IIntegration>>
) => {
	const { data: integrations } = useIntegrationList({
		options: { staleTime: Infinity, ...props.options },
	});
	return {
		data: integrations?.results?.find((i: IIntegration) => i.id === props.id),
	};
};

export function useIntegrationSpec(
	integrationId: string
): IntegrationSpec | null {
	const { data: integration, isLoading } = useIntegration({
		id: integrationId,
	});

	const { data: spec } = useMarketplaceIntegrationSpec({
		id: integration?.marketplace_integration_spec_version?.spec_id || '',
		options: {
			enabled: Boolean(integration?.marketplace_integration_spec_version),
		},
	});

	if (isLoading || !integration) {
		return null;
	}

	if (integration.marketplace_integration_spec_version) {
		if (!spec) return null;

		return {
			type: 'marketplace',
			value: spec,
		};
	}

	return {
		type: 'builtin',
		value: integrationList.find((i) => i.type === integration.type)!,
	};
}

export function useImportMetadataIntegration(integrationId: string) {
	const mutationFn = async (file: File) => {
		const url = getEndpoints(integrationsQueryKeyFactory.namespace).byPath([
			integrationId,
			'import_metadata',
		]);

		const form = new FormData();
		form.append('file', file);

		await apiClient.post(url, form);
		return true;
	};

	return useMutation({
		mutationFn,
	});
}

export function useImportJSONLMetadataIntegration(integrationId: string) {
	const mutationFn = async ({
		resourcesFile,
		lineagesFile,
	}: {
		resourcesFile: File;
		lineagesFile: File | null;
	}) => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'import_jsonl_metadata',
		]);

		const form = new FormData();
		form.append('resources_file', resourcesFile);

		if (lineagesFile) {
			form.append('lineages_file', lineagesFile);
		}

		await apiClient.post(url, form);
		return true;
	};

	return useMutation({
		mutationFn,
	});
}

export function useIntegrationGroupItems(integrationId: string) {
	const queryKey = [...INTEGRATIONS_NAMESPACE, integrationId, 'group_items'];
	const queryFn = async () => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'group_items',
		]);

		const { data } = await apiClient.get<IGroupItem[]>(url, {});
		return data;
	};

	return useQuery(queryKey, queryFn);
}

export function useIntegrationGroupItemsRefreshSupport(
	integrationType: string
) {
	const queryKey = [
		...INTEGRATIONS_NAMESPACE,
		'group_items',
		'refresh_support',
		integrationType,
	];
	const queryFn = async () => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			'group_items',
			'refresh_support',
		]);

		const { data } = await apiClient.get<boolean>(url, {
			params: {
				integration_type: integrationType,
			},
		});
		return data;
	};

	return useQuery(queryKey, queryFn);
}

export function useIntegrationGroupSettings(integrationId: string) {
	const queryKey = [...INTEGRATIONS_NAMESPACE, integrationId, 'group_settings'];
	const queryFn = async () => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'group_settings',
		]);

		const { data } = await apiClient.get<Record<string, IGroupSetting>>(
			url,
			{}
		);
		return data;
	};

	return useQuery(queryKey, queryFn);
}

export function useUpdateIntegrationGroupSettings(integrationId: string) {
	const mutationFn = async (settings: {
		group_settings: Record<string, IGroupSetting>;
	}) => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'group_settings',
		]);

		await apiClient.post(url, settings);
		showNotification({
			message: 'Integration settings updated successfully',
			color: 'green',
		});
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(integrationsQueryKeyFactory.all());
		},
	});
}

export function useRefreshIntegrationGroupItems(integrationId: string) {
	const mutationFn = async () => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'group_items',
			'refresh',
		]);

		await apiClient.post(url, {});
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(integrationsQueryKeyFactory.all());
		},
	});
}
