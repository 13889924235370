import { LiteUserOut } from '@repo/api-codegen';
import { colors } from '@repo/theme/primitives';
import { ISecodaEntity, IUser } from '../../api';
import type { SecodaEntity, User } from '../../lib/models';

/**
 * Get all of the user accounts, including service accounts that
 * have not been unchecked in the integration popularity settings.
 * Then filter for only users included in the resources external_usage
 * map. Finally, sort the filtered list by the external usage for this resource.
 * @param entity to return the users for.
 * @returns the list of external users or "service accounts".
 */
export const externalUsers = (
	users: User[] | LiteUserOut[] | IUser[],
	serviceAccounts: IUser[],
	entity: SecodaEntity | ISecodaEntity
) => {
	const countableServiceAccounts = serviceAccounts?.filter(
		(sa) => sa.service_account_count_popularity
	) as LiteUserOut[];
	return (
		countableServiceAccounts
			.concat(users)
			?.filter((user) => entity.external_users.includes(user.id)) ?? []
	);
};

/**
 * Get all frequent users *with* an account on Secoda.
 * @param entity to return the users for.
 * @returns the list of internal, frequent users.
 */
export const internalUsers = (
	users: User[],
	entity: SecodaEntity | ISecodaEntity
) => users?.filter((user) => entity.internal_users?.includes(user.id)) ?? [];

export function colorGroupToFillMap(color: string) {
	// These color are defined in Figma, but are only used for user Avatars.
	// Because of that we're not extracting them into a constant (currently at
	// least).
	// Currently we haven't used the colors as gradients becuase if the
	// difficulty of implementation.
	if (color === 'blue') {
		return { fillStart: '#C1C1F5', fillEnd: '#9998E8', textColor: '#0D0E41' };
	}
	if (color === 'green') {
		return { fillStart: '#BEEEDF', fillEnd: '#9AE3CB', textColor: '#17332D' };
	}
	if (color === 'cyan') {
		return { fillStart: '#B9EAF3', fillEnd: '#93DBE8', textColor: '#003841' };
	}
	if (color === 'lime') {
		return { fillStart: '#E1F5B8', fillEnd: '#CEED8E', textColor: '#2E4205' };
	}
	if (color === 'violet') {
		return { fillStart: '#FFBEB5', fillEnd: '#FFAA9E', textColor: '#4A0901' };
	}
	if (color === 'grape') {
		return { fillStart: '#F6BEE7', fillEnd: '#EB95D5', textColor: '#410935' };
	}
	// The disabled state uses gray
	return { fillStart: colors.gray[5], fillEnd: colors.gray[5] };
}
