import { useApiListTeams } from '@repo/api-codegen';
import { reverse } from 'lodash-es';
import type {
	IApiListResponse,
	INestedBreadcrumbs,
	ISecodaEntity,
} from '../../api';
import { useIntegrationList } from '../../api';
import { useNestedBreadcrumbsList } from '../../api/hooks/nestedBreadcrumbs';

import type { SecodaEntity } from '../../lib/models';
import { getBreadCrumbForEntity } from '../../utils/breadcrumb';

interface IBreadcrumb {
	title: string;
	href: string;
}

/**
 * Generates nestedBreadcrumbs for the given SecodaEntity.
 *
 * @param entity - The SecodaEntity for which to generate nestedBreadcrumbs.
 * @returns An array of breadcrumb objects.
 */
export function useBreadcrumbs(
	entity: SecodaEntity | ISecodaEntity
): IBreadcrumb[] | null {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const { data: integrations, isLoading: isIntegrationsListLoading } =
		useIntegrationList({
			options: {
				select: (res) => res.results,
			},
		});

	const { data: nestedBreadcrumbs, isLoading: isNestedBreadCrumbsLoading } =
		useNestedBreadcrumbsList({
			filters: {
				id: entity.id,
			},
			options: {
				select: (res: IApiListResponse<INestedBreadcrumbs>) =>
					reverse(res.results || []),
			},
		});

	if (isNestedBreadCrumbsLoading || isIntegrationsListLoading) {
		return null;
	}

	return getBreadCrumbForEntity(
		entity,
		entity.native_type ?? '',
		teams,
		nestedBreadcrumbs,
		integrations
	);
}
