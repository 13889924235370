/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import {
	Avatar,
	Badge,
	Box,
	Button,
	createStyles,
	Group,
	Text,
	Tooltip,
	UnstyledButton,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DataQualityScore, LiteUser, useApiListTeams } from '@repo/api-codegen';
import { Banner, Icon, IconButton, IconNames } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import { isEmpty } from 'lib0/object';
import { capitalize, get, isNil, lowerCase, map, noop, size } from 'lodash-es';
import React, {
	ReactNode,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useTeamPermission } from '../../api/hooks/team/myMemberships.tsx';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList.ts';
import {
	IBaseModel,
	ICollection,
	IIntegration,
	ILineage,
	ISearchMetadata,
	ISecodaEntity,
	IUser,
	IUserGroup,
	useAuthUser,
	useIntegrationPrefetched,
} from '../../api/index.ts';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../constants.ts';
import type { SecodaEntity } from '../../lib/models/index.ts';
import { EntityType } from '../../lib/types.ts';
import {
	INTEGRATION_STATUS_MAPPING,
	IntegrationStatusTones,
} from '../../pages/IntegrationsPage/constants.ts';
import { getBreadCrumbsFromSearchMetadata } from '../../utils/breadcrumb.ts';
import { entityUrl } from '../../utils/navigationUtils.ts';
import { snakeCaseToTitleCase } from '../../utils/shared.utils.ts';
import { formatShortTimeDifference, getEpoch } from '../../utils/time.ts';
import DistributionPopup from '../CatalogView/TableView/components/DistributionPopup/index.ts';
import { DateRenderer } from '../CatalogView/TableView/DateRenderer.tsx';
import ColumnProfile from '../ColumnProfile/ColumnProfile.tsx';
import { DataQualityBadgeWithHover } from '../DataQualityScore/DataQualityBadgeWithHover.tsx';
import DescriptionEditor from '../DescriptionEditor';
import { CollectionSelector } from '../EntityModal/Metadata/CollectionSelector.tsx';
import { OwnerSelector } from '../EntityModal/Metadata/OwnerSelector.tsx';
import RelatedEntitiesSelector from '../EntityModal/Metadata/RelatedEntitiesSelector.tsx';
import TagSelector from '../EntityModal/Metadata/TagSelector.tsx';
import {
	SelectableProperty,
	StaticProperty,
} from '../EntityPageLayout/EntityPropertySidebar/index.ts';
import { SELECTABLE_PROPERTY_OPTIONS } from '../EntityPageLayout/EntityPropertySidebar/SelectableProperty/constants.ts';
import PublishStatusBadge from '../Forms/PublishStatusBadge.tsx';
import IntegrationLogo from '../IntegrationLogo/index.tsx';
import MarkdownRenderer from '../MarkdownRenderer/index.ts';
import {
	closeAllModals,
	closeModal,
	openConfirmModal,
} from '../ModalManager/index.ts';
import { nFormatter } from '../SearchListItem/helpers.ts';
import { Popularity } from '../SearchListItem/template.tsx';
import SingleSelector from '../SingleSelector/SingleSelector.tsx';
import type { SelectablePropertyItem } from '../SingleSelector/types.ts';
import { UserAvatar } from '../UserAvatar/index.ts';
import { ROW_HEIGHT } from './TableV2.styles.ts';

const useStyles = createStyles(() => ({
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

export function CapitalizeTextRender<T extends ISecodaEntity | ILineage>({
	record,
	field,
	field2,
}: {
	record: T;
	field: keyof T;
	field2: keyof T;
}) {
	const { classes, theme } = useStyles();

	let value = get(record, field) ?? get(record, field2) ?? 'None';
	value = capitalize(value as string).replace('_', ' ');

	return (
		<Text
			role="link"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color={theme.colors.gray[9]}
		>
			{isEmpty(value) ? ' ' : value}
		</Text>
	);
}

export function BoldTextRender<T extends IBaseModel>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	const { classes, theme } = useStyles();

	return (
		<Text
			fw={600}
			role="link"
			className={classes.ellipsis}
			color={theme.colors.gray[9]}
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
		>
			{isEmpty(get(record, field) as unknown as object)
				? 'Untitled'
				: (get(record, field) as ReactNode)}
		</Text>
	);
}

export function TextRender<T extends IBaseModel>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	const { classes, theme } = useStyles();

	const value = get(record, field);
	return (
		<Text
			role="link"
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color={theme.colors.gray[9]}
		>
			{isEmpty(value as unknown as object) && typeof value !== 'number'
				? ''
				: (value as ReactNode)}
		</Text>
	);
}

export function TitleRender({
	record,
	modalId,
}: {
	record: {
		id: string;
		title?: string;
		icon?: string;
		onExpandClick?: (id: string) => void;
		paramExpandedLevel?: number;
		paramExpanded?: boolean;
	};
	modalId?: string;
}) {
	const { onExpandClick, id, paramExpanded, paramExpandedLevel } = record;

	const navigate = useNavigate();
	const theme = useMantineTheme();
	const [expanded, setExpanded] = useState(paramExpanded ?? false);

	const { classes } = useStyles();

	const textRef = useRef<HTMLDivElement>(null);

	const handleNavigate = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			e.preventDefault();

			const url = entityUrl(record as unknown as SecodaEntity);

			if (modalId) {
				closeModal(modalId);
			}

			// If holding the `meta` key, open the entity in a new tab.
			if (e.metaKey) {
				window.open(url, '_blank');
			} else {
				navigate(url);
			}
		},
		[modalId, navigate, record]
	);

	const level = paramExpandedLevel ?? 0;
	const isLeaf = !onExpandClick && level > 0;

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	return (
		<Group
			data-testid="title-render"
			spacing={0}
			sx={{
				flexWrap: 'nowrap',
				marginLeft: level * 20,
				paddingLeft: isLeaf ? 28 : 0,
				height: ROW_HEIGHT,
			}}
		>
			{onExpandClick && (
				<Box
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						setExpanded((prev) => !prev);
						onExpandClick(id);
					}}
				>
					<IconButton
						style={{
							transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
							transition: 'transform 0.2s ease',
						}}
						iconName="chevronRight"
						variant="tertiary"
					/>
				</Box>
			)}
			{record.icon && !record.icon.includes(".svg") && (
				<Box
					pl={onExpandClick ? theme.spacing['2xs'] : 0}
					pr={theme.spacing['2xs']}
				>
					{record.icon}
				</Box>
			)}
			{/* If we are expandable, `navigate` is set to false in the coldef
			 and we need to handle clicks on the title directly. */}
			<Box
				px={onExpandClick ? theme.spacing['2xs'] : 0}
				display="flex"
				h="100%"
				w="100%"
				onClick={handleNavigate}
				sx={{
					overflow: 'hidden',
				}}
			>
				<Tooltip
					maw={theme.other.space[90]}
					sx={{
						overflowWrap: 'break-word',
						whiteSpace: 'normal',
					}}
					zIndex={1000}
					withinPortal
					position="top"
					pos="absolute"
					openDelay={1000}
					label={record?.title}
					hidden={isEmpty(record?.title) || isEllipsisHidden(textRef.current)}
				>
					<Text
						role="link"
						ref={textRef}
						sx={{
							lineHeight: `${ROW_HEIGHT}px`,
						}}
						className={classes.ellipsis}
						color={theme.colors.gray[9]}
						fw={600}
					>
						{isEmpty(record?.title) ? 'Untitled' : record?.title}
					</Text>
				</Tooltip>
			</Box>
		</Group>
	);
}

export function ReadOnlyFormSubmissionStatusRender(record: { status: string }) {
	const status = record?.status;
	return (
		<Badge color={status?.toLowerCase() === 'approved' ? 'green' : 'gray'}>
			{status}
		</Badge>
	);
}

export function ReadOnlyOwnerRender({
	record,
}: {
	record: {
		user?: IUser | LiteUser | undefined | null;
	};
}) {
	const user = record?.user;
	const { classes } = useStyles();

	if (!user) {
		return null;
	}

	return (
		<Group spacing="xs">
			<UserAvatar size="xxs" user={user as IUser} />
			<Text className={classes.ellipsis}>{user?.display_name}</Text>
		</Group>
	);
}

export function ReadOnlyStatusRender({
	record,
}: {
	record: { status?: boolean };
}) {
	return <PublishStatusBadge value={record?.status} />;
}

export function ReadOnlyOwnersRender({ owners }: { owners?: IUser[] }) {
	const { classes } = useStyles();

	return (
		<Group spacing={'xs'} noWrap>
			<Avatar.Group spacing="xs">
				{map(owners, (user) => (
					<UserAvatar size="sm" user={user} />
				))}
			</Avatar.Group>
			<Text className={classes.ellipsis}>
				{size(owners) === 1 && owners?.[0].display_name}
				{size(owners) > 1 && `${owners?.length} people`}
			</Text>
		</Group>
	);
}

export function ReadOnlyOwnersIdsRender<T>({
	record,
	accessor,
}: {
	record: T;
	accessor: keyof T;
}) {
	const { activeUsers } = useExtendedUserList({});
	const owners =
		// eslint-disable-next-line react/destructuring-assignment
		activeUsers?.filter((user) => get(record, accessor)?.includes(user.id)) ??
		[];

	return <ReadOnlyOwnersRender owners={owners as IUser[]} />;
}

export function UserRender<T>({
	record,
	accessor,
	esAccessor,
}: {
	record: T;
	accessor: keyof T;
	esAccessor?: keyof T;
}) {
	const value = get(record, accessor) ?? get(record, esAccessor ?? accessor);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { activeUsers } = useExtendedUserList({});
	const owners = activeUsers?.filter((user) => value === user.id) ?? [];

	return <ReadOnlyOwnersRender owners={owners} />;
}

export function ColumnProfileRender(record: {
	title: string;

	search_metadata: {
		type: string;
		isPrimaryKey: boolean;
	};

	type: string;

	properties: {
		_profile: {
			count: number;
			max: number;
			median: number;
			unique: number;
			percent_filled: number;
			min: number;
			mean: number;
			ntiles: {
				is_numeric: boolean;
				left: number;
				right: number;
				label: string;
				frequency: number;
			}[];
		};
	};
}) {
	const [opened, { close, open }] = useDisclosure(false);

	if (!record.properties?._profile) {
		return null;
	}

	return (
		<>
			{opened && (
				<DistributionPopup
					fieldName={record.title}
					opened={opened}
					onClose={close}
					profileData={record.properties._profile}
					columnType={get(record, 'search_metadata.type')}
					isPrimaryKey={get(record, 'search_metadata.is_pk') ?? false}
				/>
			)}
			<Group onClick={open}>
				<ColumnProfile data={record?.properties?._profile.ntiles} />
			</Group>
		</>
	);
}

export function TagRender({
	onChange,
	record,
}: {
	onChange?: (id: string) => (value: string[]) => void;
	record: {
		id: string;
		tags: ({ id: string } | string | undefined | null)[] | string;
		integration?: string;
	};
}) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { id, tags, integration: integrationId } = record;

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});

	const maintainTags = !!integration?.credentials.import_tags_preference;

	const tagsList = Array.isArray(tags) ? tags : [tags];

	const tagIds = (tagsList ?? [])
		.map((tag) => (typeof tag === 'object' ? tag?.id : tag))
		.filter((tag: string | undefined | null) => !isNil(tag)) as string[];

	const noTags = maintainTags && tagIds.length === 0;

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<TagSelector
				key={tagIds.join('-')}
				hideOnEmpty
				readOnly={!write || isNil(onChange) || noTags}
				onChange={onChange?.(id)}
				placeholder={!write || noTags ? 'No tags' : 'Add tag'}
				forceVariant="tertiary"
				initialValue={tagIds}
				maintainTags={maintainTags}
				entityIntegrationId={integrationId}
				entityIntegrationName={integration?.name}
			/>
		</Box>
	);
}

export function ParentRenderer({
	record,
}: {
	record: { search_metadata?: ISearchMetadata };
}) {
	const { search_metadata: searchMetadata } = record;
	const breadcrumbs = getBreadCrumbsFromSearchMetadata(searchMetadata);

	const { classes, theme } = useStyles();

	const textRef = useRef<HTMLDivElement>(null);

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	return (
		<Tooltip
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			withinPortal
			hidden={isEllipsisHidden(textRef.current)}
			label={breadcrumbs.join(' / ')}
		>
			<Text ref={textRef} className={classes.ellipsis}>
				{breadcrumbs.join(' / ')}
			</Text>
		</Tooltip>
	);
}

export function OwnerRender<T extends ISecodaEntity>({
	record,
	onChangeUserOwners,
	onChangeGroupOwners,
}: {
	record: T;
	onChangeUserOwners?: (id: string) => (value: string[]) => void;
	onChangeGroupOwners?: (id: string) => (value: string[]) => void;
}) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { id, owners, owners_groups, integration: integrationId } = record;
	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});
	const maintainOwners = !!integration?.credentials.import_owners_preference;

	const withUserOwners = !isNil(onChangeUserOwners);
	const withUserGroupOwners = !isNil(onChangeGroupOwners);

	const ownerIds = useMemo(() => {
		const userIds =
			owners
				?.map((user) => (typeof user === 'object' ? (user as IUser).id : user))
				.filter((owner) => !isNil(owner)) ?? [];

		const userGroupIds =
			owners_groups
				?.map((group) =>
					typeof group === 'object' ? (group as IUserGroup).id : group
				)
				.filter((owner) => !isNil(owner)) ?? [];

		return [...userIds, ...(withUserGroupOwners ? userGroupIds : [])];
	}, [owners, owners_groups, withUserGroupOwners]);

	const noOwners = maintainOwners && ownerIds.length === 0;

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<OwnerSelector
				forceVariant="tertiary"
				hideOnEmpty
				initialValue={ownerIds}
				maintainOwners={maintainOwners}
				entityIntegrationId={integrationId}
				entityIntegrationName={integration?.name}
				key={ownerIds?.join('-')}
				onChangeUserOwners={onChangeUserOwners?.(id)}
				onChangeGroupOwners={onChangeGroupOwners?.(id)}
				placeholder={!write || noOwners ? 'No owners' : 'Add owner'}
				readOnly={
					!write || !(withUserOwners || withUserGroupOwners) || noOwners
				}
			/>
		</Box>
	);
}

export function RelativeTimeRender<T>({
	record,
	field,
}: {
	record: T;
	field: string;
}) {
	const datetimeValue = get(record, field);
	const theme = useMantineTheme();

	if (!datetimeValue) {
		return null;
	}

	return (
		<Tooltip
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			withinPortal
			label={dayjs(datetimeValue).format('YYYY-MM-DD HH:mm:ss')}
		>
			<Text>
				{datetimeValue && formatShortTimeDifference(datetimeValue, true)}
			</Text>
		</Tooltip>
	);
}

export function ReadOnlyBadgeRender<T>({
	record,
	field,
	options,
	nilOption,
}: {
	record: T;
	field: string;
	options: {
		color: string;
		option: string | boolean | number;
		label?: string;
	}[];
	nilOption: {
		color: string;
		option: string | boolean | number;
		label?: string;
	};
}) {
	function lowerCaseIfString(el: string | boolean | number) {
		return typeof el === 'string' ? lowerCase(el) : el;
	}

	const color =
		options.find(
			(option) =>
				lowerCaseIfString(option.option) ===
				lowerCaseIfString(get(record, field))
		)?.color ?? nilOption.color;

	return (
		<Badge color={color}>
			{get(record, field)
				? (
						options.find(
							(option) =>
								lowerCaseIfString(option.option) ===
								lowerCaseIfString(get(record, field))
						)?.label ?? capitalize(get(record, field))
					)?.replace(/_/g, ' ')
				: capitalize(nilOption.label).replace(/_/g, ' ')}
		</Badge>
	);
}

export function ReadOnlyBadgeWithToneRender<T>({
	record,
	field,
	options,
	nilOption,
}: {
	record: T;
	field: string;
	options: {
		tone: IntegrationStatusTones;
		label: string;
		option: string;
		iconName?: IconNames;
	}[];
	nilOption: {
		tone: IntegrationStatusTones;
		label: string;
		option: string;
		iconName?: IconNames;
	};
}) {
	const fieldValue = get(record, field);
	const matchedOption = options.find((option) => option.option === fieldValue);

	if (!fieldValue || !matchedOption) {
		const nilLabel = nilOption.label
			? capitalize(nilOption.label).replace(/_/g, ' ')
			: '';
		return <Badge variant={nilOption.tone}>{nilLabel}</Badge>;
	}

	const { tone, label, iconName } = matchedOption;
	const formattedLabel = (label ?? capitalize(fieldValue as string)).replace(
		/_/g,
		' '
	);

	return (
		<Badge variant={tone} leftSection={iconName && <Icon name={iconName} />}>
			{formattedLabel}
		</Badge>
	);
}

export function IntegrationStatusRender({ record }: { record: IIntegration }) {
	const options = useMemo(
		() =>
			Object.entries(INTEGRATION_STATUS_MAPPING).map(([key, value]) => ({
				tone: value.tone,
				label: value.label,
				option: key,
				iconName: value.iconName,
			})),
		[]
	);

	const nilOption = useMemo(
		() => ({
			tone: INTEGRATION_STATUS_MAPPING.pending.tone,
			label: INTEGRATION_STATUS_MAPPING.pending.label,
			option: 'pending',
		}),
		[]
	);

	if (record.type === 'slack') {
		return null;
	}

	return (
		<ReadOnlyBadgeWithToneRender
			record={record}
			field="status"
			options={options}
			nilOption={nilOption}
		/>
	);
}

export function SelectorWithIconRender<T extends IBaseModel>({
	record,
	accessor,
	esAccessor,
	nilOption,
	onChange,
}: {
	record: T;
	accessor: string;
	esAccessor: string;
	nilOption: string | boolean;
	onChange?: (id: string) => (value: any) => void;
}) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const theme = useMantineTheme();
	const { write } = useTeamPermission();
	const { id } = record;

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SingleSelector
				variant="tertiary"
				searchable
				readOnly={!write || isNil(onChange)}
				property={accessor}
				placeholder={`No ${accessor}`}
				options={SELECTABLE_PROPERTY_OPTIONS[accessor]}
				onChange={onChange?.(id)}
				isViewerUser={!write}
				initialSelected={
					(record?.[accessor as keyof T] ??
						record?.[esAccessor as keyof T] ??
						nilOption) as string
				}
				iconType={esAccessor === 'question_status' ? 'badge' : 'tabler'}
				hideOnEmpty
			/>
		</Box>
	);
}

export function BadgeRender<T extends IBaseModel>({
	record,
	field,
	badgeOptions,
	nilOption,
	onChange,
}: {
	record: T;
	field: string;
	badgeOptions: { color: string; option: string | boolean; label?: string }[];
	nilOption: { color: string; option: string | boolean; label?: string };
	onChange?: (id: string) => (value: any) => void;
}) {
	const { id } = record;
	const { write } = useTeamPermission();
	const theme = useMantineTheme();

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const options = useMemo(
		() =>
			badgeOptions.map((option) => ({
				value: option.option,
				label: option.label!,
				color: option.color as SecodaThemeShades,
			})),
		[badgeOptions]
	);

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SingleSelector
				readOnly={!write || isNil(onChange)}
				hideOnEmpty
				searchable
				variant="tertiary"
				iconType="badge"
				isViewerUser={!write}
				placeholder={`No ${field === 'published' ? 'status' : field}`}
				onChange={onChange?.(id)}
				initialSelected={
					(record?.[field as keyof T] as string) ?? nilOption.option
				}
				property={field}
				options={options}
			/>
		</Box>
	);
}

export function TeamRender({
	record,
	onChange,
}: {
	record: {
		id: string;
		teams: ({ id: string } | string)[];
	};
	onChange?: (id: string) => (value: unknown) => void;
}) {
	const { data } = useApiListTeams({});

	return (
		<SelectableProperty
			type="multi"
			value="teams"
			iconType="emoji"
			isViewerUser={isNil(onChange)}
			onChange={onChange?.(record.id)}
			selected={record.teams as string[]}
			options={data?.results.map((team) => ({
				value: team.id,
				label: team.name,
				icon: team.icon,
			}))}
		/>
	);
}

export function CollectionRender<T extends ISecodaEntity>({
	record,
	onChange,
}: {
	record: T;
	onChange?: (id: string) => (value: string[]) => void;
}) {
	const { id, collections } = record;

	const { write } = useTeamPermission();

	const theme = useMantineTheme();

	const collectionIds = collections
		.map((collection) =>
			typeof collection === 'object'
				? (collection as ICollection)?.id
				: collection
		)
		.filter((collection) => !isNil(collection)) as string[];

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<CollectionSelector
				key={collectionIds?.join('-')}
				hideOnEmpty
				forceVariant="tertiary"
				onChange={onChange?.(id)}
				initialValue={collectionIds}
				readOnly={!write || isNil(onChange)}
				placeholder={!write ? 'No collections' : 'Add to collection'}
			/>
		</Box>
	);
}

export function RelatedEntitiesRender({
	entity,
}: {
	entity: ISecodaEntity & { related_entities: ISecodaEntity[] };
}) {
	return <RelatedEntitiesSelector initialValue={entity.related_entities} />;
}

export function StatusRender(record: { status: string | boolean | string[] }) {
	const theme = useMantineTheme();
	const { write } = useTeamPermission();

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<SelectableProperty
				readOnly={!write}
				placeholder={!write ? 'No status' : 'Add status'}
				selected={record?.status}
				type="single"
				value="status"
				iconType="tabler"
				isViewerUser={!write}
			/>
		</Box>
	);
}

const useDescriptionStyles = createStyles(
	(theme, { disabled }: { disabled: boolean }) => {
		const { write } = useTeamPermission();
		return {
			hidden: {
				visibility: 'hidden',
			},
			wrapper: {
				height: `${ROW_HEIGHT - 16}px`,
				marginLeft: `-${theme.spacing.xs}`,
				borderRadius: theme.radius.sm,
				paddingLeft: theme.spacing.xs,
				paddingRight: theme.spacing.xs,
				// Necessary to hide the overflow of the markdown renderer.
				overflow: 'hidden',
				maxWidth: '100%',
				'&:hover': {
					backgroundColor: !write
						? undefined
						: theme.other.getColor('fill/transparent/hover'),
				},
			},
			markdown: {
				lineHeight: `${theme.other.typography.lineHeight.text.xl}`,
				height: `${theme.other.space[7]}px`,
				width: '100%',
				p: {
					lineHeight: `${theme.other.typography.lineHeight.text.xl}`,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
			descriptionEditor: {
				backgroundColor: disabled
					? theme.other.getColor('surface/secondary/default')
					: theme.other.getColor('fill/transparent/default'),
				padding: '1rem',
				width: '100%',
				borderRadius: theme.radius.sm,
				border: `${theme.other.space[0.25]}px solid ${theme.other.getColor(
					'border/input/default'
				)}`,
				'&:focus-within, &:focus': {
					outline: '1px solid #0066CC',
				},
			},
			noDescription: {
				color: theme.other.getColor('text/secondary/default'),
			},
		};
	}
);

export function DescriptionRender<T extends ISecodaEntity>({
	record,
	field,
	onChange,
}: {
	record: T;
	field: string;
	onChange?: (id: string) => (value: string) => void;
}) {
	const { id, integration: integrationId } = record;

	// This can include custom properties under the path `properties.custom.` so
	// we must use `get` here.
	const description = get(record, field);

	const { isAdminUser } = useAuthUser();
	const { write } = useTeamPermission();

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: { enabled: !!integrationId },
	});

	const frozenDescription =
		!!integration?.credentials.use_native_descriptions &&
		!field.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { classes: descriptionStyles, theme } = useDescriptionStyles({
		disabled: frozenDescription,
	});

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [inputValue, setInputValue] = useState<string>(description ?? '');

	// If an optimistic update is made, we need to update the input value.
	useUpdateEffect(() => {
		setInputValue(description ?? '');
	}, [description]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const ref = useRef<string>(description ?? '');
	const descriptionRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const updateRef = useCallback((value: string) => {
		ref.current = value;
	}, []);

	const isEllipsisHidden = useCallback(
		(parent: HTMLDivElement | null, child: HTMLDivElement | null) =>
			parent && child ? parent.offsetWidth - 16 > child.offsetWidth : false,
		[]
	);

	const saveDescription = useCallback(() => {
		setInputValue(ref.current);
		onChange?.(id)(ref.current);
		closeAllModals();
	}, [id, onChange]);

	const handleModal = useCallback(() => {
		if (!onChange) {
			return;
		}

		openConfirmModal({
			withCloseButton: true,
			withinPortal: false,
			closeOnClickOutside: true,
			labels: {
				confirm: frozenDescription ? null : 'Save',
				cancel: frozenDescription ? null : 'Cancel',
			},
			confirmProps: {
				onClick: () => {
					saveDescription();
				},
			},
			cancelProps: {
				color: 'gray',
				onClick: () => {
					closeAllModals();
				},
			},
			title: (
				<Text size="md" weight="bold">
					Edit description
				</Text>
			),
			children: (
				<Group>
					<Group className={descriptionStyles.descriptionEditor}>
						{frozenDescription && !inputValue ? (
							<Text className={descriptionStyles.noDescription}>
								No description
							</Text>
						) : (
							<DescriptionEditor
								entityId={id}
								value={inputValue}
								onChange={updateRef}
								save={saveDescription}
								readOnly={frozenDescription}
							/>
						)}
					</Group>
					{frozenDescription && (
						<Banner
							isInsideCard
							message={
								isAdminUser ? (
									<Group spacing="xs">
										<Text>
											{description
												? `Description comes from ${integration?.name}. To maintain in Secoda, go to integration settings.`
												: `No description set in ${integration?.name}. To maintain in Secoda, go to integration settings.`}
										</Text>
										<Link to={`/integrations/${integrationId}/preferences`}>
											<Button onClick={() => closeAllModals()}>
												{integration?.name} settings
											</Button>
										</Link>
									</Group>
								) : (
									<Text>Description comes from {integration?.name}.</Text>
								)
							}
							onDismiss={noop}
							tone="info"
						/>
					)}
				</Group>
			),
		});
	}, [
		onChange,
		frozenDescription,
		descriptionStyles.descriptionEditor,
		descriptionStyles.noDescription,
		inputValue,
		id,
		updateRef,
		saveDescription,
		isAdminUser,
		description,
		integration?.name,
		integrationId,
	]);

	return (
		<Tooltip
			withinPortal
			multiline
			maw={theme.other.space[90]}
			sx={{
				overflowWrap: 'break-word',
				whiteSpace: 'normal',
			}}
			label={inputValue}
			hidden={
				isEmpty(inputValue) ||
				isEllipsisHidden(wrapperRef.current, descriptionRef.current)
			}
		>
			<Box
				ref={wrapperRef}
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{write && (
					<UnstyledButton
						className={descriptionStyles.wrapper}
						onClick={handleModal}
					>
						{isEmpty(inputValue) && (
							<Text
								className={descriptionStyles.hidden}
								color={theme.other.getColor('text/secondary/default')}
								size="sm"
							>
								{frozenDescription ? 'No description' : 'Add description'}
							</Text>
						)}
						{!isEmpty(inputValue) && (
							<MarkdownRenderer
								ref={descriptionRef}
								className={descriptionStyles.markdown}
								inline
								lineClamp={1}
							>
								{inputValue}
							</MarkdownRenderer>
						)}
					</UnstyledButton>
				)}
				{!write && !isEmpty(inputValue) && (
					<MarkdownRenderer
						ref={descriptionRef}
						className={descriptionStyles.markdown}
						inline
						lineClamp={1}
					>
						{inputValue}
					</MarkdownRenderer>
				)}
			</Box>
		</Tooltip>
	);
}

export function PopularityRender({
	record,
}: {
	record: {
		entity_type: string;
		external_usage: number;
	};
}) {
	const theme = useMantineTheme();
	const { entity_type: entityType, external_usage: externalUsage } = record;

	if (isNil(externalUsage)) {
		return null;
	}

	if (entityType === EntityType.column) {
		return null;
	}

	return (
		<Group spacing={theme.spacing.xs}>
			<Popularity level={Math.log10(externalUsage)} />
			<Text size="xs" color={theme.other.getColor('text/primary/default')}>
				{nFormatter(externalUsage, 1)}{' '}
				{entityType === EntityType.table ? 'queries' : 'views'}
			</Text>
		</Group>
	);
}

export function RenderWithIntegrationLogo({
	textContent,
	integrationType,
	integrationId,
}: {
	textContent?: string;
	integrationType?: string;
	integrationId?: string;
}) {
	const { classes, theme } = useStyles();

	return (
		<Group display="flex" noWrap spacing={theme.spacing.xs}>
			<IntegrationLogo
				integrationId={integrationId}
				integrationType={integrationType}
				width={theme.other.space[4]}
				height={theme.other.space[4]}
			/>
			<Text
				sx={{
					lineHeight: `${theme.other.space[4]}px`,
				}}
				className={classes.ellipsis}
			>
				{snakeCaseToTitleCase(textContent ?? '')}
			</Text>
		</Group>
	);
}

export function IntegrationRender({
	record,
}: {
	record: { integration: string };
}) {
	const { integration: integrationId } = record;
	const { data: integration } = useIntegrationPrefetched({ id: integrationId });

	if (!integration) {
		return null;
	}

	return (
		<RenderWithIntegrationLogo
			integrationType={integration.type}
			textContent={integration.name}
		/>
	);
}

export function MonitorResourceRender({
	record,
}: {
	record: {
		display_metadata?: {
			target?: {
				label?: string;
				integration_type?: string;
			};
		};
	};
}) {
	return (
		<RenderWithIntegrationLogo
			// eslint-disable-next-line react/destructuring-assignment
			integrationType={record.display_metadata?.target?.integration_type ?? ''}
			// eslint-disable-next-line react/destructuring-assignment
			textContent={record.display_metadata?.target?.label ?? ''}
		/>
	);
}

export function QueryResourceRender(record: {
	related_entities?: {
		id?: string | null | undefined;
		integration_id?: string | null | undefined;
		title_full?: string | null | undefined;
		title?: string | null | undefined;
		entity_type?: string | null | undefined;
	}[];
}) {
	return (
		<StaticProperty
			label={`${record.related_entities?.length ?? 0} Resources`}
			value={
				(record.related_entities || []).map((entity) => ({
					label: entity.title_full || entity.title,
					value: entity.id,
					icon: (
						<IntegrationLogo
							height={18}
							width={18}
							entityType={(entity?.entity_type as EntityType) ?? ''}
							integrationId={entity?.integration_id ?? ''}
						/>
					),
				})) as SelectablePropertyItem[]
			}
			type="tables"
			forceInline
		/>
	);
}

function DateRender({ value }: { value: string | undefined }) {
	return <DateRenderer value={value} />;
}

export function CreatedAtRender({
	record,
}: {
	record: { created_at?: string | undefined };
}) {
	return <DateRender value={record?.created_at} />;
}

export function SQLRender(record: { sql?: string | undefined | null }) {
	// Only render first 100 characters of the SQL query
	const sql = record.sql || '';

	return (
		<Tooltip multiline w={500} withinPortal label={record.sql || ''}>
			<Text>{sql.slice(0, 100)}...</Text>
		</Tooltip>
	);
}

export function CostRender({
	record,
}: {
	record: {
		estimated_cost?: number | undefined | null;
	};
}) {
	const { estimated_cost } = record;
	const theme = useMantineTheme();

	return (
		<Text color={theme.colors.gray[9]}>
			{estimated_cost ? `$${estimated_cost.toFixed(2)}` : 'N/A'}
		</Text>
	);
}

export function RuntimeRender({
	record,
}: {
	record: { runtime?: number | undefined | null };
}) {
	const { runtime } = record;
	const theme = useMantineTheme();

	return <Text color={theme.colors.gray[9]}>{`${runtime || 0} ms`}</Text>;
}

export function LastUpdatedRender<T>({
	record,
	field,
}: {
	record: T;
	field: keyof T;
}) {
	return <DateRender value={get(record, field)} />;
}

export function DurationRender<T>({
	record,
	field1,
	field2,
}: {
	record: T;
	field1: keyof T;
	field2: keyof T;
}) {
	const { classes, theme } = useStyles();

	const createdAt = get(record, field1);
	const finishedAt = get(record, field2);

	if (createdAt && finishedAt) {
		const durationSeconds = Math.abs(
			getEpoch(createdAt) - getEpoch(finishedAt)
		);

		const minutes = Math.floor(durationSeconds / 60);
		const seconds = durationSeconds % 60;

		return (
			<Text
				role="link"
				sx={{
					lineHeight: `${ROW_HEIGHT}px`,
				}}
				className={classes.ellipsis}
				color={theme.colors.gray[9]}
			>
				{`${minutes > 0 ? `${minutes}m` : ''} ${seconds}s`}
			</Text>
		);
	}

	return null;
}

export function SQLTitleRender(record: {
	title?: string | undefined | null;
	dialect?: string | undefined | null;
}) {
	const { classes, theme } = useStyles();
	const queryTitle = capitalize(record.title || `${record.dialect} Query`);

	return (
		<Text
			sx={{
				lineHeight: `${ROW_HEIGHT}px`,
			}}
			className={classes.ellipsis}
			color={theme.colors.gray[9]}
			fw={600}
		>
			{queryTitle}
		</Text>
	);
}

export function DataQualityRender<T>({
	record,
}: {
	record: T & { dqs?: DataQualityScore };
}) {
	const ref = useRef<HTMLDivElement>(null);

	return <DataQualityBadgeWithHover dqs={record?.dqs} ref={ref} />;
}

export function TotalRunTimeRender(record: {
	total_runtime?: number | undefined | null;
}) {
	const { total_runtime } = record;
	const theme = useMantineTheme();

	const textWithUnit = useMemo(() => {
		if (total_runtime) {
			if (total_runtime < 1000) {
				return `${total_runtime.toFixed(0)} ms`;
			} else if (total_runtime < 60000) {
				return `${(total_runtime / 1000).toFixed(0)} s`;
			} else if (total_runtime < 3600000) {
				return `${(total_runtime / 60000).toFixed(2)} min`;
			} else {
				return `${(total_runtime / 3600000).toFixed(2)} h`;
			}
		}

		return 'N/A';
	}, [total_runtime]);

	return (
		<Text ta="right" color={theme.colors.gray[9]}>
			{textWithUnit}
		</Text>
	);
}

export function AverageRunTimeRender(record: {
	average_runtime?: number | undefined | null;
}) {
	const { average_runtime } = record;
	const theme = useMantineTheme();

	const textWithUnit = useMemo(() => {
		if (average_runtime) {
			if (average_runtime < 1000) {
				return `${average_runtime.toFixed(0)} ms`;
			} else if (average_runtime < 60000) {
				return `${(average_runtime / 1000).toFixed(0)} s`;
			} else {
				return `${(average_runtime / 60000).toFixed(2)} min`;
			}
		}

		return 'N/A';
	}, [average_runtime]);

	return (
		<Text ta="right" color={theme.colors.gray[9]}>
			{textWithUnit}
		</Text>
	);
}

export function TotalRunsRender(record: {
	total_runs?: number | undefined | null;
}) {
	const { total_runs } = record;
	const theme = useMantineTheme();

	const textWithUnit = useMemo(() => {
		if (total_runs) {
			return `${total_runs}`;
		}

		return 'N/A';
	}, [total_runs]);

	return (
		<Text ta="right" color={theme.colors.gray[9]}>
			{textWithUnit}
		</Text>
	);
}

export function ReadOnlyQueryFrequentUsersRender(record: {
	frequent_users?: LiteUser[] | undefined | null;
}) {
	if (!record.frequent_users) {
		return null;
	}

	const { frequent_users } = record;
	return <ReadOnlyOwnersRender owners={frequent_users as unknown as IUser[]} />;
}

export function DateTimeRender<T>({
	record,
	field,
}: {
	record: T;
	field: string;
}) {
	const datetimeValue = get(record, field);

	if (!datetimeValue) {
		return null;
	}

	return <Text>{dayjs(datetimeValue).format('MMM D, YYYY [at] h:mm A')}</Text>;
}
