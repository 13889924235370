import { EditorView } from 'prosemirror-view';
import { ClientRectObject } from '@floating-ui/react';
import { captureError } from '../../../../../../web-tracing';
import { CommandMenuType } from './CommandMenu';

export function getSelectionRect(view: EditorView): ClientRectObject {
	const {
		state: { selection },
	} = view;

	let fromPos;
	let toPos;
	try {
		fromPos = view.coordsAtPos(selection.from);
		toPos = view.coordsAtPos(selection.to, -1);
	} catch (err) {
		// this should not happen!
		captureError(err);
		return {
			x: 0,
			y: 0,
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: 0,
			height: 0,
		};
	}

	const top = Math.min(fromPos.top, toPos.top);
	const bottom = Math.max(fromPos.bottom, toPos.bottom);
	const left = Math.min(fromPos.left, toPos.left);
	const right = Math.max(fromPos.right, toPos.right);

	// ensure that start < end for the menu to be positioned correctly
	return {
		x: top,
		y: left,
		top,
		bottom,
		left,
		right,
		width: right - left,
		height: bottom - top,
	};
}

export function isCommandMenuType(str?: string): str is CommandMenuType {
	if (!str) {
		return false;
	}
	return [
		'block-menu-container',
		'emoji-menu-container',
		'mention-menu-container',
	].includes(str);
}
