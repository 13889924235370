import { Stack } from '@mantine/core';
import { Filter } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { QueryKey } from '@tanstack/react-query';
import {
	ApiCatalogSort,
	IApiListResponse,
	ISecodaEntity,
	Namespace,
	queryClient,
	searchQueryKeyFactory,
} from '../../api';
import { apiClient, getEndpoints } from '../../api/common';
import { search, SecodaEntity } from '../../lib/models';
import { EntityType } from '../../lib/types';
import { getSummaryAndBreadCrumbs } from '../../utils/breadcrumb';
import { SecodaEntityIcon } from '../SecodaEntity';
import { FilterItem, FilterValueType, SortValue } from './types';

export async function fetchQuery<T>(
	getQueryKey: (page: number, searchTerm: string) => QueryKey,
	getQueryFn: (
		page: number,
		searchTerm: string
	) => Promise<IApiListResponse<T>>,
	page: number,
	searchTerm: string,
	filterFn: (searchTerm: string, result: T[]) => T[]
): Promise<T[]> {
	// check if the entire result set (total_pages == 1) is in the query cache
	const queryState = queryClient.getQueryState<IApiListResponse<T>>(
		getQueryKey(page, '')
	);
	if (queryState?.data?.total_pages === 1) {
		// if it is, we apply a local filter to avoid an extra API call
		return filterFn(searchTerm, queryState.data.results ?? []);
	}

	// call the API
	const { results } = await queryClient.fetchQuery<IApiListResponse<T>>(
		getQueryKey(page, searchTerm),
		() => getQueryFn(page, searchTerm)
	);

	return results;
}

export function buildEntityGetItemsFn(entityType: EntityType) {
	return async (page: number = 1, searchTerm: string = '') => {
		const getFilterParams = (filterPage: number, filterSearchTerm: string) => ({
			search_term: filterSearchTerm,
			sort: { field: SortValue.RELEVANCE, order: 'desc' } as ApiCatalogSort,
			filters: {
				operands: [
					{
						operands: [],
						operator: 'exact',
						field: 'entity_type',
						value: entityType,
					},
					{
						operands: [],
						operator: 'exact',
						field: 'native_type',
						value: entityType,
					},
				],
				operator: 'and',
			} as Filter,
			page: filterPage,
		});
		const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
			searchQueryKeyFactory.list(
				page,
				getFilterParams(filterPage, filterSearchTerm)
			);
		const getQueryFn = async (filterPage: number, filterSearchTerm: string) => {
			const { data } = await search(
				getFilterParams(filterPage, filterSearchTerm)
			);
			return data;
		};

		const results = await fetchQuery<ISecodaEntity>(
			getQueryKey,
			getQueryFn,
			page,
			searchTerm,
			(filterSearchTerm, result) =>
				result.filter(
					(entity) =>
						!searchTerm ||
						entity.title?.toLowerCase().includes(filterSearchTerm.toLowerCase())
				)
		);

		return (
			results?.map((entity) => {
				let entityId = entity.id;

				if (
					entity.entity_type === EntityType.user &&
					entity.display_metadata.owners?.[0]?.id
				) {
					entityId = entity.display_metadata.owners?.[0]?.id;
				}

				return {
					label: entity.title || 'Untitled',
					value: entityId,
					metadata: entity,
					icon: (
						<SecodaEntityIcon entity={{ ...entity, id: entityId }} size={20} />
					),
				};
			}) ?? []
		);
	};
}

export function buildGetItemsById(namespace: Namespace) {
	return async (ids: string[]): Promise<FilterItem[]> => {
		const promises = ids.map(async (id) => {
			const url = getEndpoints(namespace).byId(id);
			const entity = await apiClient.get(url).then((data) => data.data);

			return {
				label: entity.title ?? entity.name,
				value: entity.id,
				metadata: entity,
				icon: <SecodaEntityIcon entity={entity} size={20} />,
			};
		});

		return Promise.all(promises);
	};
}

export async function buildGetItemsByIdFromSearch(ids: string[]) {
	const searchParams = {
		sort: { field: '_score', order: 'desc' } as ApiCatalogSort,
		filters: {
			operands: [],
			operator: 'in',
			field: 'id',
			value: ids,
		} as Filter,
		page: 1,
	};

	const { results } = await queryClient.fetchQuery<
		IApiListResponse<ISecodaEntity>
	>(searchQueryKeyFactory.list(1, searchParams), async () => {
		const { data } = await search(searchParams);
		return data;
	});

	return (
		results?.map((entity) => {
			let entityId = entity.id;

			if (
				entity.entity_type === EntityType.user &&
				entity.display_metadata.owners?.[0]?.id
			) {
				entityId = entity.display_metadata.owners?.[0]?.id;
			}

			return {
				label: entity.title || 'Untitled',
				value: entityId,
				metadata: entity,
				icon: (
					<SecodaEntityIcon entity={{ ...entity, id: entityId }} size={20} />
				),
			};
		}) ?? []
	);
}

export function convertBooleanFilterToIsSetFilter(field: string) {
	return function (value: FilterValueType | null): Filter {
		const filterValue: Filter = {
			operands: [],
			operator: 'is_set',
			field,
			value: null,
		};

		return value
			? filterValue
			: {
					operator: 'not',
					operands: [filterValue],
				};
	};
}

export function renderFilterItemWithBreadcrumbs(withType?: boolean) {
	return function render(item: FilterItem) {
		const entity = item.metadata as SecodaEntity;

		const breadcrumbs = getSummaryAndBreadCrumbs(
			withType ? entity.native_type || entity.entity_type : null,
			entity.search_metadata
		);

		return (
			<Stack spacing={0}>
				<Text size="sm" lineClamp={1}>
					{entity.title || 'Untitled'}
				</Text>
				<Text size="xs" color="text/secondary/default" lineClamp={1}>
					{breadcrumbs}
				</Text>
			</Stack>
		);
	};
}
