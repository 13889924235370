/* eslint-disable no-param-reassign */
import {
	Badge,
	Box,
	Checkbox,
	Divider,
	Skeleton,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconNames, Text } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { getColor } from '@repo/theme/utils';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { every, isEmpty, isNil, map, noop, size, uniq } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import type {
	DefaultContext,
	IApiListResponse,
	ISecodaEntity,
	ITag,
	UpdateRequestParams,
} from '../../api';
import {
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
	useBulkDeleteSecodaEntities,
	useBulkGenerateAiMetadata,
	useBulkUpdateSecodaEntities,
	useCollectionListAll,
	useSearch,
	useTagList,
	useUpdateSecodaEntity,
	useWorkspace,
} from '../../api';
import { UpdateModelHook } from '../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import type { RequestData } from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { UserGroup } from '../../lib/models';
import { EntityType } from '../../lib/types';
import { WithOnlyIdRequired } from '../../lib/typescript';
import { pluralize } from '../../utils/stringUtils';
import { useBackgroundJob2 } from '../BackgroundJobProgress/BackgroundJob2.hooks';
import { CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE } from '../CatalogView/constants';
import { CatalogServerType } from '../CatalogView/types';
import entityDrawerStore from '../EntityDrawer/store';
import { SELECTABLE_PROPERTY_OPTIONS } from '../EntityPageLayout/EntityPropertySidebar/SelectableProperty/constants';
import { getOwnerAndGroupSelectorOptions } from '../EntityPageLayout/utils';
import { FilterOptionType } from '../Filter';
import { openConfirmModal, openModal } from '../ModalManager';
import SearchBox from '../SearchBox/SearchBox';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import { closeSpotlight } from '../Spotlight';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';
import {
	optimisticUpdateById,
	optimisticUpdateLists,
} from '../../api/hooks/base/useUpdateBaseModel';
import {
	BadgeRender,
	CollectionRender,
	DataQualityRender,
	DescriptionRender,
	LastUpdatedRender,
	OwnerRender,
	RelatedEntitiesRender,
	SelectorWithIconRender,
	TagRender,
	TitleRender,
} from './render';
import type { ExtendedDataTableColumn } from './types';

type ActionProperty =
	| 'collections'
	| 'tags'
	| 'owners'
	| 'published'
	| 'pii'
	| 'verified'
	| 'owners_groups'
	| 'assigned_to'
	| 'priority'
	| 'status'
	| 'parent';

interface UseGenericColumnsProps<T extends ISecodaEntity> {
	useUpdate?: UpdateModelHook<
		T,
		UpdateRequestParams<T>,
		DefaultContext<T>,
		unknown
	>;
}

export function useGenericColumns<T extends ISecodaEntity>({
	useUpdate = useUpdateSecodaEntity,
}: UseGenericColumnsProps<T>): ExtendedDataTableColumn<T>[] {
	const { mutateAsync: update } = useUpdate({});

	const handleChange = useCallback(
		(field: keyof T) => (id: T['id']) => (value: string | string[] | boolean) =>
			update(
				{
					data: {
						id,
						[field]: value,
					} as unknown as WithOnlyIdRequired<T>,
				},
				{
					onSuccess: () => {},
				}
			),
		[update]
	);

	const columns: ExtendedDataTableColumn<T>[] = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Title',
				render: (record) => <TitleRender record={record} />,
				width: 200,
				filterOptionType: FilterOptionType.TITLE,
			},
			{
				navigate: false,
				accessor: 'description',
				title: 'Description',
				render: (record) => (
					<DescriptionRender
						record={record as ISecodaEntity}
						onChange={handleChange('description')}
						field={'description'}
					/>
				),
				width: 350,
				filterOptionType: FilterOptionType.DESCRIPTION,
			},
			{
				navigate: false,
				accessor: 'owners',
				title: 'Owners',
				render: (record) => (
					<OwnerRender
						record={record as ISecodaEntity}
						onChangeUserOwners={handleChange('owners')}
						onChangeGroupOwners={handleChange('owners_groups')}
					/>
				),
				width: 200,
				filterOptionType: FilterOptionType.OWNERS,
			},
			{
				navigate: false,
				accessor: 'tags',
				title: 'Tags',
				render: (record) => (
					<TagRender
						record={record as ISecodaEntity}
						onChange={handleChange('tags')}
					/>
				),
				width: 200,
				filterOptionType: FilterOptionType.TAGS,
			},
			{
				navigate: false,
				accessor: 'collections',
				title: 'Collections',
				render: (record) => (
					<CollectionRender
						record={record as ISecodaEntity}
						onChange={handleChange('collections')}
					/>
				),
				width: 200,
				filterOptionType: FilterOptionType.COLLECTIONS,
			},
			{
				navigate: false,
				accessor: 'related_entities',
				title: 'Related',
				render: (record) => (
					<RelatedEntitiesRender
						entity={
							record as unknown as ISecodaEntity & {
								related_entities: ISecodaEntity[];
							}
						}
					/>
				),
			},
			{
				accessor: 'pii',
				title: 'Governance',
				navigate: false,
				render: (record) => (
					<SelectorWithIconRender
						record={record as ISecodaEntity}
						accessor={'pii'}
						esAccessor="pii"
						nilOption={false}
						onChange={handleChange('pii')}
					/>
				),
				width: 150,
				filterOptionType: FilterOptionType.PII,
			},
			{
				accessor: 'verified',
				title: 'Verified',
				navigate: false,
				render: (record) => (
					<SelectorWithIconRender
						record={record as ISecodaEntity}
						accessor={'verified'}
						esAccessor="verified"
						nilOption={false}
						onChange={handleChange('verified')}
					/>
				),
				width: 150,
				filterOptionType: FilterOptionType.VERIFICATION,
			},
			{
				accessor: 'published',
				title: 'Status',
				navigate: false,
				render: (record) => (
					<BadgeRender
						record={record as ISecodaEntity}
						field={'published'}
						onChange={handleChange('published')}
						badgeOptions={[
							{
								color: 'fill/success-secondary/default',
								option: true,
								label: 'Published',
							},
							{
								color: 'fill/secondary/default',
								option: false,
								label: 'Draft',
							},
						]}
						nilOption={{
							color: 'fill/secondary/default',
							option: false,
							label: 'Draft',
						}}
					/>
				),
				width: 150,
				filterOptionType: FilterOptionType.PUBLISHED,
			},
			{
				accessor: 'updated_at',
				title: 'Last updated',
				render: (record) => (
					<LastUpdatedRender record={record} field={'updated_at'} />
				),
				width: 150,
				filterOptionType: FilterOptionType.UPDATED_TIME,
			},
		],
		[handleChange]
	);

	return columns;
}

export function useDataQualityScoreColumns<T extends ISecodaEntity>({
	catalogType,
}: {
	catalogType?: CatalogServerType;
}) {
	const { workspace } = useWorkspace();

	return useMemo(() => {
		if (
			catalogType &&
			workspace.quality_enabled &&
			CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE.includes(catalogType)
		) {
			return [
				{
					accessor: 'dqs',
					title: 'Quality',
					navigate: false,
					render: (record: T) => <DataQualityRender record={record} />,
					width: 150,
					filterOptionType: FilterOptionType.DATA_QUALITY,
				},
			];
		}

		return [];
	}, [catalogType, workspace]);
}

export function ActionCheckbox({
	item,
	handleTrigger,
	state,
}: {
	item: SelectablePropertyItem;
	handleTrigger: (value: string | boolean) => void;
	state: 'checked' | 'unchecked' | 'indeterminate';
}) {
	const theme = useMantineTheme();

	return (
		<Box
			py={'xs'}
			px={'sm'}
			sx={{
				display: 'flex',
				gap: theme.spacing.sm,
				borderRadius: theme.radius.sm,
				border: 'none',
				background: 'none',
				':hover': {
					backgroundColor: getColor('surface/primary/hover'),
				},
			}}
			component="button"
			onClick={() => handleTrigger(item.value)}
		>
			<Checkbox
				indeterminate={state === 'indeterminate'}
				checked={state === 'checked'}
			/>
			<Text size="sm" color="text/primary/default">
				{item.label}
			</Text>
		</Box>
	);
}

export function ActionModal<T extends ISecodaEntity>({
	property,
	single,
	defaultSelected,
	withLocalProperties,
	withSearch,
	bulkUpdateSecodaEntities,
}: {
	defaultSelected: T[];
	property: ActionProperty;
	single: boolean;
	withSearch?: Record<string, string>;
	withLocalProperties?: SelectablePropertyItem[];
	bulkUpdateSecodaEntities: UseMutateAsyncFunction<
		string[],
		unknown,
		RequestData,
		unknown
	>;
}) {
	const [selected, setSelected] = useState<T[]>(defaultSelected);

	const theme = useMantineTheme();

	const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string>(
		'',
		withSearch ? 300 : 10
	);

	const { isFetching, data: parentData } = useSearch({
		searchTerm: debouncedSearch,
		filters: withSearch,
		options: {
			enabled: !withLocalProperties,
			select: ({ results }) =>
				results
					?.filter(
						(r) =>
							!selected.map((s) => s.id).includes(r.id) &&
							r.entity_type === selected?.[0].entity_type
					)
					.map(
						({ id, title }) =>
							({
								label: title,
								value: id,
							}) as SelectablePropertyItem
					),
		},
	});

	const handleSearch = useCallback(
		(searchTerm: string) => {
			setDebouncedSearch(searchTerm);
		},
		[setDebouncedSearch]
	);

	const isIndeterminate = useCallback(
		(value: string | boolean) => {
			if (single) {
				return selected.some(
					(entity) => entity[property as keyof ISecodaEntity] === value
				);
			} else {
				return selected.some((entity) =>
					(entity[property as keyof ISecodaEntity] as string[]).includes(
						String(value)
					)
				);
			}
		},
		[property, selected, single]
	);

	const isChecked = useCallback(
		(value: string | boolean) => {
			if (single) {
				return every(
					selected,
					(entity) => entity[property as keyof ISecodaEntity] === value
				);
			} else {
				return selected.every((entity) =>
					(entity[property as keyof ISecodaEntity] as string[]).includes(
						String(value)
					)
				);
			}
		},
		[property, selected, single]
	);

	const items = withLocalProperties ? withLocalProperties : parentData;

	const handleTrigger = useCallback(
		async (value: string | boolean) => {
			const everyChecked =
				!single &&
				every(selected, (entity) =>
					(entity[property as keyof ISecodaEntity] as string[]).includes(
						value as string
					)
				);

			const data = selected.map((select: T) => {
				const computePropertyValue = () => {
					if (single) {
						return value;
					} else {
						if (
							// Always add the value if it's not present *in all* selected entities.
							everyChecked
						) {
							return (
								select[property as keyof ISecodaEntity] as string[]
							).filter((item) => item !== value);
						} else {
							return uniq([
								...((select[property as keyof ISecodaEntity] ??
									[]) as string[]),
								value as string,
							]);
						}
					}
				};

				const newValue = computePropertyValue();

				setSelected((prevSelected) => {
					const updatedSelected = prevSelected.map((prev) => {
						if (prev.id === select.id) {
							return {
								...prev,
								[property]: newValue,
							};
						}
						return prev;
					});
					return updatedSelected;
				});

				return {
					id: select.id,
					data: {
						[property]: newValue,
					},
				};
			});

			await bulkUpdateSecodaEntities(
				{
					data,
				},
				{
					onSuccess: () => {
						closeSpotlight('bulkActions');
						data.forEach((el) => {
							const updatedValue = {
								id: el.id,
								...el.data,
							};
							optimisticUpdateLists(updatedValue, [
								resourceCatalogQueryKeyFactory.all(),
							]);
							optimisticUpdateById(
								updatedValue,
								secodaEntitiesQueryKeyFactory?.byId(updatedValue.id)
							);
						});
					},
				}
			);
		},
		[bulkUpdateSecodaEntities, property, selected, single]
	);

	const RESOURCE_LABEL = 'resource';

	return (
		<Box m={`-${theme.spacing.sm}`}>
			<Badge
				ml={theme.spacing.sm}
				mb={0}
				color="gray"
				size="sm"
				sx={{
					textTransform: 'lowercase',
				}}
			>
				{selected.length}{' '}
				{selected.length > 1 ? pluralize(RESOURCE_LABEL) : RESOURCE_LABEL}
			</Badge>
			{property !== 'pii' &&
				property !== 'verified' &&
				property !== 'published' && (
					<>
						<SearchBox
							size="md"
							icon={undefined}
							className={undefined}
							classNames={undefined}
							variant="default"
							placeholder={'Search...'}
							onlySearchOnEnter={false}
							onSearch={handleSearch}
							onCancelSearch={noop}
							defaultSearchTerm=""
							autoFocus={!isEmpty(debouncedSearch)}
							sx={{
								input: {
									boxShadow: 'none',
									border: 'none',
								},
								'input:focus': {
									border: 'none',
									boxShadow: 'none',
								},
							}}
						/>
						<Divider mx={`-${theme.spacing.md}`} />
					</>
				)}
			{(isNil(withSearch) || !isEmpty(debouncedSearch)) && (
				<Stack mx={`${-theme.spacing.sm}`} mt={theme.spacing.xs} spacing={0}>
					{isFetching && <Skeleton h={theme.other.space[80]} />}
					{!isFetching &&
						items
							?.filter((item) =>
								item.label
									?.toLowerCase()
									.includes(debouncedSearch?.toLowerCase())
							)
							?.map((item) => (
								<ActionCheckbox
									key={`${item.label}-${item.value}`}
									handleTrigger={handleTrigger}
									item={item}
									state={
										// eslint-disable-next-line no-nested-ternary
										isChecked(item.value)
											? 'checked'
											: isIndeterminate(item.value)
												? 'indeterminate'
												: 'unchecked'
									}
								/>
							))}
				</Stack>
			)}
		</Box>
	);
}

export function onClickGenericAction<T extends ISecodaEntity>(
	property: ActionProperty,
	single: boolean,
	bulkUpdateSecodaEntities: UseMutateAsyncFunction<
		string[],
		unknown,
		RequestData,
		unknown
	>,
	withLocalProperties?: SelectablePropertyItem[],
	withSearch?: Record<string, string>
) {
	return async (selected: T[]) => {
		closeSpotlight('bulkActions');
		openModal({
			withCloseButton: false,
			children: (
				<ActionModal<T>
					single={single}
					property={property}
					defaultSelected={selected}
					withLocalProperties={withLocalProperties}
					withSearch={withSearch}
					bulkUpdateSecodaEntities={bulkUpdateSecodaEntities}
				/>
			),
		});
	};
}

export function useAiAction<T>() {
	const { mutateAsync: bulkGenerateAiMetadata } = useBulkGenerateAiMetadata();

	const [startJob] = useBackgroundJob2(
		resourceCatalogQueryKeyFactory.allLists(),
		'AI description generation completed'
	);

	const handleOnClick = useCallback(
		(selected: T[]) => {
			openConfirmModal({
				variant: 'default',
				title: `Generate AI description for ${selected.length} selected resources?`,
				children: (
					<Text size="md" pt="md">
						This will overwrite any existing descriptions. This can&apos;t be
						undone?
					</Text>
				),
				labels: {
					cancel: 'Cancel',
					confirm: 'Generate',
				},
				confirmProps: {
					variant: 'primary',
				},
				onConfirm: async () => {
					const { jobId } = await bulkGenerateAiMetadata({
						data: {
							field: 'description',
							entity_ids: map(selected, 'id'),
							bulk_generate: true,
						},
					});
					startJob(jobId);
				},
			});
		},
		[bulkGenerateAiMetadata, startJob]
	);

	return useMemo(
		() => ({
			id: 'actions::ai',
			title: 'Apply AI description',
			name: 'Apply AI description',
			iconName: 'sparkles' as IconNames,
			hotkey: '/ai',
			type: EntityType.all,
			team: undefined,
			category: 'actions',
			show: true,
			onClick: handleOnClick,
		}),

		[handleOnClick]
	);
}

export function useGenericActions<
	T extends ISecodaEntity,
>(): ICommandListItem<T>[] {
	const { isEditorOrAdminUser } = useAuthUser();

	const aiAction = useAiAction<T>();

	const handleEditInSidebar = useCallback(
		(id: string, entityType: EntityType) => {
			entityDrawerStore
				.openEntityDrawerById(
					isEditorOrAdminUser,
					id,
					entityType,
					resourceCatalogQueryKeyFactory.all()
				)
				.then();
		},
		[isEditorOrAdminUser]
	);

	const { mutateAsync: bulkUpdateSecodaEntities } =
		useBulkUpdateSecodaEntities();

	const { mutateAsync: bulkDeleteSecodaEntities } = useBulkDeleteSecodaEntities(
		{ name: 'Entities' }
	);

	const { activeUsers, userGroups } = useExtendedUserList({});

	const usersOptions = useMemo(
		() => getOwnerAndGroupSelectorOptions(activeUsers ?? [], [], noop, noop),
		[activeUsers]
	);

	const userGroupOptions = useMemo(
		() =>
			getOwnerAndGroupSelectorOptions(
				[],
				(userGroups ?? []) as unknown as UserGroup[],
				noop,
				noop
			),
		[userGroups]
	);

	const { data: collectionData } = useCollectionListAll();

	const collections = useMemo(
		() =>
			collectionData?.results?.map((collection) => ({
				label: collection.title || 'Untitled',
				value: collection.id,
				icon: collection.icon,
			})) ?? [],
		[collectionData?.results]
	);

	const { data: tags } = useTagList({
		options: {
			select: (data: IApiListResponse<ITag>) =>
				data.results.map(
					(tag) =>
						({
							label: tag.name,
							value: tag.id,
							color: tag.color as SecodaThemeShades,
						}) as SelectablePropertyItem
				),
		},
	});

	const actions = useMemo(
		() => [
			{
				id: 'actions::collections',
				title: 'Set collections',
				name: 'Set collections',
				iconName: 'folders' as IconNames,
				hotkey: '/sc',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'collections',
					false,
					bulkUpdateSecodaEntities,
					collections
				),
			},
			{
				id: 'actions::tags',
				title: 'Set tags',
				name: 'Set tags',
				iconName: 'tag' as IconNames,
				hotkey: '/st',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'tags',
					false,
					bulkUpdateSecodaEntities,
					tags ?? []
				),
			},
			{
				id: 'actions::owners',
				title: 'Set user owners',
				name: 'Set user owners',
				iconName: 'user' as IconNames,
				hotkey: '/us',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'owners',
					false,
					bulkUpdateSecodaEntities,
					usersOptions
				),
			},
			{
				id: 'actions::owners_groups',
				title: 'Set group owners',
				name: 'Set group owners',
				iconName: 'userCircle' as IconNames,
				hotkey: '/gp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'owners_groups',
					false,
					bulkUpdateSecodaEntities,
					userGroupOptions
				),
			},
			{
				id: 'actions::published',
				title: 'Set published',
				name: 'Set published',
				iconName: 'circle' as IconNames,
				hotkey: '/mp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'published',
					true,
					bulkUpdateSecodaEntities,
					SELECTABLE_PROPERTY_OPTIONS.published
				),
			},
			{
				id: 'actions::pii',
				title: 'Set PII',
				name: 'Set PII',
				iconName: 'shieldLockUnfilled' as IconNames,
				hotkey: '/mp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'pii',
					true,
					bulkUpdateSecodaEntities,
					SELECTABLE_PROPERTY_OPTIONS.pii
				),
			},
			{
				id: 'actions::verified',
				title: 'Set verification',
				name: 'Set verification',
				iconName: 'discountCheck' as IconNames,
				hotkey: '/vf',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'verified',
					true,
					bulkUpdateSecodaEntities,
					SELECTABLE_PROPERTY_OPTIONS.verified
				),
			},
			{
				id: 'actions::sidebar',
				title: 'Edit in sidebar',
				name: 'Edit in sidebar',
				iconName: 'layoutSidebarRightExpand' as const,
				hotkey: '/sb',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: T[]) => size(selected) === 1,
				onClick: async (selected: T[]) => {
					if (size(selected) === 1) {
						closeSpotlight('bulkActions');
						handleEditInSidebar(selected[0].id, (selected[0] as T).entity_type);
					}
				},
			},
			aiAction,
			{
				id: 'actions::delete',
				title: 'Delete',
				name: 'Delete',
				show: true,
				iconName: 'trash' as const,
				hotkey: '/dq',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				onClick: async (selected: T[], clearSelected: VoidFunction) => {
					let textChildSuffix = '';

					const someHaveChildren = selected.some(
						(item) => item.has_child_of_same_type
					);

					if (someHaveChildren) {
						if (selected.length === 1) {
							textChildSuffix = ' and its children';
						} else {
							textChildSuffix = ' and their children';
						}
					}

					openConfirmModal({
						title: 'Permanently delete',
						children:
							selected.length === 1
								? `Are you sure you want to delete (1) item${textChildSuffix} from your workspace?`
								: `Are you sure you want to delete these (${selected.length}) items${textChildSuffix} from your workspace?`,
						labels: {
							cancel: 'Cancel',
							confirm: 'Delete',
						},
						onConfirm: async () => {
							await bulkDeleteSecodaEntities(
								{
									data: selected.map(({ id }) => ({
										id,
									})),
								},
								{
									onSuccess: () => {
										clearSelected();
										closeSpotlight('bulkActions');
										queryClient.invalidateQueries(
											resourceCatalogQueryKeyFactory.allLists()
										);
									},
								}
							);
						},
					});
				},
			},
		],
		[
			aiAction,
			bulkDeleteSecodaEntities,
			bulkUpdateSecodaEntities,
			collections,
			handleEditInSidebar,
			tags,
			userGroupOptions,
			usersOptions,
		]
	);

	return actions;
}
