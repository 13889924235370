import { useApiGetTeamListUsers, useApiListTeams } from '@repo/api-codegen';
import { isEmpty } from 'lodash-es';
import { useUserGroupList } from '../userGroup';
import { useUserList } from './index';

interface IUseExtendedUserListArgs {
	suspense?: boolean;
}

export function useGuestUserList({ suspense }: IUseExtendedUserListArgs) {
	const { data: JoinedTeams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const teamId = JoinedTeams?.[0]?.id || '';

	const { data: usersVisibleToGuests } = useApiGetTeamListUsers(
		{
			pathParams: {
				teamId,
			},
		},
		{
			suspense,
			select: ({ results }) => results,
			enabled: !isEmpty(teamId),
		}
	);

	return { usersVisibleToGuests };
}

export function useExtendedUserList({ suspense }: IUseExtendedUserListArgs) {
	const { data: activeUsers } = useUserList({
		filters: {
			disabled: false,
			is_service_account: false,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	const { data: disabledUsers } = useUserList({
		filters: {
			disabled: true,
			is_service_account: false,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	const { data: serviceAccountsDisabled } = useUserList({
		filters: {
			is_service_account: true,
			disabled: true,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	const { data: serviceAccountsActive } = useUserList({
		filters: {
			is_service_account: true,
			disabled: false,
		},
		options: {
			select: ({ results }) => results,
		},
	});

	const { data: userGroups } = useUserGroupList({
		options: {
			select: ({ results }) => results,
		},
	});

	return {
		userGroups,
		activeUsers,
		disabledUsers,
		serviceAccountsActive,
		serviceAccountsDisabled,
	};
}
