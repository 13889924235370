import type { LiteUserOut, TeamOut } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiGetMyMemberships,
	useApiGetTeam,
	useApiGetTeamListUsers,
	useApiUpdateTeam,
} from '@repo/api-codegen';
import { useQueryClient } from '@tanstack/react-query';
import { curry, find, isEmpty, isNil, noop } from 'lodash-es';
import { useIsTeamsPage } from '../../../api/hooks/team';

type IUseHomepageTeamResult =
	| {
			isTeamsPage: true;
			team: TeamOut;
			isTeamViewerUser: boolean;
			isTeamEditorUser: boolean;
			teamMembers: LiteUserOut[];
			updateTeam: (
				key: keyof TeamOut
			) => (value: TeamOut[keyof TeamOut]) => void;
	  }
	| {
			isTeamsPage: false;
			team: null;
			isTeamViewerUser: boolean;
			isTeamEditorUser: boolean;
			teamMembers: [];
			updateTeam: VoidFunction;
	  };

export function useHomepageTeam(): IUseHomepageTeamResult {
	const queryClient = useQueryClient();
	const { isTeamsPage, teamId } = useIsTeamsPage();

	const {
		isLoading,
		isError,
		data: team,
	} = useApiGetTeam(
		{
			pathParams: {
				teamId: teamId ?? '',
			},
		},
		{
			enabled: isTeamsPage && !isNil(teamId),
			suspense: false,
			keepPreviousData: false,
		}
	);

	const { mutateAsync: updateTeam } = useApiUpdateTeam({});

	const { data: myMemberships } = useApiGetMyMemberships(
		{
			pathParams: {
				teamId: teamId ?? '',
			},
		},
		{
			enabled: !isNil(teamId),
		}
	);

	const { data: teamMembers } = useApiGetTeamListUsers(
		{
			pathParams: {
				teamId: teamId ?? '',
			},
		},
		{
			suspense: false,
			enabled: isTeamsPage && !isEmpty(teamId),
			select: ({ results }) => results,
		}
	);

	if (!isTeamsPage || isLoading || isError) {
		return {
			isTeamsPage: false,
			isTeamViewerUser: false,
			isTeamEditorUser: false,
			team: null,
			teamMembers: [],
			updateTeam: noop,
		};
	}

	const handleUpdateTeam = curry(
		(key: keyof TeamOut, value: TeamOut[keyof TeamOut]) => {
			updateTeam(
				{
					pathParams: {
						teamId: team?.id ?? '',
					},
					body: {
						[key]: value,
					},
				},
				{
					onSettled: () => {
						queryClient.invalidateQueries(
							apiQueryKey('auth/teams/{team_id}', { team_id: team?.id })
						);
					},
				}
			);
		}
	);

	const isTeamViewerUser =
		find(myMemberships, { team_id: team.id })?.write !== true;
	const isTeamEditorUser =
		find(myMemberships, { team_id: team.id })?.write === true;

	return {
		isTeamsPage: true,
		isTeamViewerUser,
		isTeamEditorUser,
		team,
		teamMembers: teamMembers || [],
		updateTeam: handleUpdateTeam,
	};
}
