import { apiQueryKey } from '@repo/api-codegen';
import type {
	ColumnProperties,
	ISecodaEntity,
	NonColumnProperties,
	SecodaProperties,
	UserSearchEntityProperties,
} from '../../api';
import queryClient from '../../api/queryClient';
import type { UUID } from '../types';
import { EntityType } from '../types';
import { BaseModel } from './base';

export class SecodaEntity extends BaseModel {
	static CLASS_NAME = 'SecodaEntity';

	// @ts-expect-error TS(2564): Property 'databuilder_id' has no initializer and is n... Remove this comment to see the full error message
	databuilder_id: string;

	// @ts-expect-error TS(2564): Property 'entity_type' has no initializer and is n... Remove this comment to see the full error message
	entity_type: EntityType;

	// @ts-expect-error TS(2564): Property 'native_type' has no initializer and is n... Remove this comment to see the full error message
	native_type: string;

	// @ts-expect-error TS(2564): Property 'icon' has no initializer and is not defi... Remove this comment to see the full error message
	icon: string;

	// @ts-expect-error TS(2564): Property 'title' has no initializer and is not def... Remove this comment to see the full error message
	title: string;

	// @ts-expect-error TS(2564): Property 'title_full' has no initializer and is no... Remove this comment to see the full error message
	title_full: string;

	// @ts-expect-error TS(2564): Property 'title_cased' has no initializer and is n... Remove this comment to see the full error message
	title_cased: string;

	// @ts-expect-error TS(2564): Property 'description' has no initializer and is n... Remove this comment to see the full error message
	description: string;

	// @ts-expect-error TS(2564): Property 'definition' has no initializer and is no... Remove this comment to see the full error message
	definition: string;

	// @ts-expect-error TS(2564): Property 'definition' has no initializer and is no... Remove this comment to see the full error message
	definition_version: number;

	// @ts-expect-error TS(2564): Property 'owners' has no initializer and is not de... Remove this comment to see the full error message
	owners: any[];

	// @ts-expect-error TS(2564)
	teams: string[];

	// @ts-expect-error TS(2564): Property 'multiplayers' has no initializer and is ... Remove this comment to see the full error message
	multiplayers: string[];

	// @ts-expect-error TS(2564): Property 'multiplayer_last_modified_by' has no ini... Remove this comment to see the full error message
	multiplayer_last_modified_by: string;

	// @ts-expect-error TS(2564): Property 'multiplayer_last_modified' has no initia... Remove this comment to see the full error message
	multiplayer_last_modified: string;

	base_entity_id: string | undefined;

	// @ts-expect-error TS(2564): Property 'created_at' has no initializer and is no... Remove this comment to see the full error message
	created_at: string;

	// @ts-expect-error TS(2564): Property 'updated_at' has no initializer and is no... Remove this comment to see the full error message
	updated_at: string;

	// @ts-expect-error TS(2564): Property 'external_usage' has no initializer and i... Remove this comment to see the full error message
	external_usage: number;

	// @ts-expect-error TS(2564): Property 'external_updated_at' has no initializer ... Remove this comment to see the full error message
	external_updated_at: string;

	// @ts-expect-error TS(2564): Property 'verified' has no initializer and is not ... Remove this comment to see the full error message
	verified: boolean;

	// @ts-expect-error TS(2564): Property 'pii' has no initializer and is not defin... Remove this comment to see the full error message
	pii: boolean;

	// @ts-expect-error TS(2564): Property 'published' has no initializer and is not... Remove this comment to see the full error message
	published: boolean;

	// @ts-expect-error TS(2564): Property 'archived' has no initializer and is not ... Remove this comment to see the full error message
	archived: boolean;

	// @ts-expect-error TS(2564): Property 'url' has no initializer and is not defin... Remove this comment to see the full error message
	url: string;

	// @ts-expect-error TS(2564): Property 'parent' has no initializer and is not de... Remove this comment to see the full error message
	parent: string | null;

	// @ts-expect-error TS(2564): Property 'read_access' has no initializer and is n... Remove this comment to see the full error message
	read_access: boolean;

	// @ts-expect-error TS(2564): Property 'write_access' has no initializer and is ... Remove this comment to see the full error message
	write_access: boolean;

	// @ts-expect-error TS(2564): Property 'execute_access' has no initializer and i... Remove this comment to see the full error message
	execute_access: boolean;

	// @ts-expect-error TS(2564): Property 'children_count' has no initializer and i... Remove this comment to see the full error message
	children_count: number;

	// @ts-expect-error TS(2564): Property 'internal_users' has no initializer and i... Remove this comment to see the full error message
	internal_users: string[];

	// @ts-expect-error TS(2564)
	external_users: UUID[];

	// @ts-expect-error TS(2564): Property 'properties' has no initializer and is no... Remove this comment to see the full error message
	properties: SecodaProperties; // Json field.

	// @ts-expect-error TS(2564): Property 'search_metadata' has no initializer and ... Remove this comment to see the full error message
	search_metadata: Record<string, any>;

	// @ts-expect-error TS(2564): Property 'bookmarked_by' has no initializer and is... Remove this comment to see the full error message
	bookmarked_by: string[]; // User ids.

	// @ts-expect-error TS(2564): Property 'collections' has no initializer and is n... Remove this comment to see the full error message
	collections: any[]; // Collection ids.

	// @ts-expect-error TS(2564): Property 'tags' has no initializer and is not defi... Remove this comment to see the full error message
	tags: any[]; // Tag ids.

	// @ts-expect-error TS(2564): Property 'sources' has no initializer and is not d... Remove this comment to see the full error message
	sources: string[]; // List of secondary sources for this entity.

	integration?: string;

	// @ts-expect-error TS(2564): Property 'owners_groups' has no initializer and is... Remove this comment to see the full error message
	owners_groups: string[];

	// @ts-expect-error TS(2564): Property 'internal_usage' has no initializer and is not ... Remove this comment to see the full error message
	internal_usage: number;

	// @ts-expect-error TS(2564): Property 'subscribers' has no initializer and is not ... Remove this comment to see the full error message
	subscribers: string[];

	current_user_permissions?: {
		read: boolean;
		write: boolean;
		execute: boolean;
	};

	row_count?: string;

	byte_size?: string;

	constructor(obj?: SecodaEntity | { id: string }) {
		super(obj);
		// eslint-disable-next-line guard-for-in
		for (const property in obj) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(this as any)[property] = (obj as any)[property];
		}
	}

	static get fields() {
		return BaseModel.fields.concat([
			'databuilder_id',
			'tags',
			'entity_type',
			'native_type',
			'icon',
			'title',
			'title_cased',
			'title_full',
			'description',
			'definition',
			'definition_version',
			'owners',
			'multiplayer_last_modified',
			'multiplayer_last_modified_by',
			'multiplayers',
			'collections',
			'workspace_id',
			'published',
			'verified',
			'pii',
			'archived',
			'url',
			'parent',
			'sources',
			'parent_id',
			'read_access',
			'write_access',
			'execute_access',
			'children_count',
			'internal_users',
			'external_users',
			'properties',
			'bookmarked_by',
			'integration',
			'search_metadata',
			'external_usage',
			'internal_usage',
			'external_updated_at',
			'owners_groups',
			'base_entity_id',
			'subscribers',
			'teams',
			'current_user_permissions',
			'byte_size',
			'row_count',
		]);
	}

	static get namespace(): string[] {
		return ['resource', 'all'];
	}

	async save(updateFields: string[] = []) {
		const result = await super.save(updateFields);

		if (updateFields.includes('teams')) {
			await queryClient.invalidateQueries(
				apiQueryKey('resource/all/{entity_id}/permissions_v2', {
					entity_id: this.id,
				})
			);
		}

		return result;
	}
}

/**
 * Type guards for SecodaEntity's properties
 */
export function isColumnProperties<T extends SecodaEntity | ISecodaEntity>(
	entity: T
): entity is T & { properties: ColumnProperties } {
	return entity.entity_type === EntityType.column;
}

export function isUserSearchEntityProperties<
	T extends SecodaEntity | ISecodaEntity,
>(entity: T): entity is T & { properties: UserSearchEntityProperties } {
	return entity.entity_type === EntityType.user;
}

export function isNonColumnProperties<T extends SecodaEntity | ISecodaEntity>(
	entity: T
): entity is T & { properties: NonColumnProperties } {
	return (
		entity.entity_type !== EntityType.column &&
		entity.entity_type !== EntityType.user
	);
}
