import { Group, Stack, createStyles } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import { Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { DataQualityDetailsGroup } from '../DataQualityDetailsCard/DataQualityDetailsCardGroup';
import { QualityScoreRing } from '../QualityScoreRing/QualityScoreRing';
import { DATA_QUALITY_TOP_KEYS } from '../constants';

const useStyles = createStyles((theme) => ({
	wrapper: {
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		background: theme.other.getColor('surface/primary/default'),
	},
	details: {
		flex: 1,
	},
}));

interface DataQualityShareContentProps {
	dataQualityScore: DataQualityScore;
}

export const DataQualityShareContent = forwardRef(
	(
		{ dataQualityScore }: DataQualityShareContentProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { classes } = useStyles();
		return (
			<Stack spacing={0} className={classes.wrapper} ref={ref}>
				<Stack px="md" pt="sm" spacing="3xs">
					<Title size="sm">Quality score</Title>
					<Text size="sm" color="text/secondary/default">
						{dayjs().format('MMM DD, YYYY')}
					</Text>
				</Stack>
				<Group p="2xl" spacing="3xl">
					<QualityScoreRing score={dataQualityScore.total} size="md" />
					<Stack spacing="xs" className={classes.details}>
						{DATA_QUALITY_TOP_KEYS.map((key) => (
							<DataQualityDetailsGroup
								key={key}
								score={dataQualityScore[key]}
								scoreType="percentage"
								dataQualityKey={key}
							/>
						))}
					</Stack>
				</Group>
			</Stack>
		);
	}
);
DataQualityShareContent.displayName = 'DataQualityShareContent';
