import { createStyles } from '@mantine/core';
import { integrationList } from '@repo/common/constants/integration/integrations';
import {
	integrationsQueryKeyFactory,
	useAuthUser,
	useCreateIntegration,
} from '../../../api';
import { useMarketplaceIntegrationSpecList } from '../../../api/hooks/marketplace';
import { useWorkspaceEnv } from '../../../api/hooks/workspace/useWorkspaceEnv';
import { useNavigate } from '../../../hooks/useNavigate';
import { usePlan } from '../../../hooks/usePlans';
import type { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../../utils/envs';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { IntegrationSpecCard } from '../../IntegrationSpecCard/IntegrationSpecCard';
import { closeModal } from '../../ModalManager';
import { toTitleCase } from '../../SearchListItem/helpers';
import WidgetSelector from '../../WidgetSelector';
import type { WidgetData } from '../../WidgetSelector/types';
import { NEW_INTEGRATION_MODAL_ID } from './constants';

export const useStyles = createStyles((theme) => ({
	header: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
	},
	body: {
		padding: 0,
	},
	widgetItemsWrapper: {
		columnGap: theme.spacing.lg,
		rowGap: theme.spacing.md,
	},
	widgetWrapper: {
		paddingBottom: 0,
	},
}));

function AddIntegrationModal() {
	const { theme, classes } = useStyles();
	const { integrationV3 } = useFeatureFlags();
	const navigate = useNavigate();
	const { workspace } = useAuthUser();
	const { isPayingCustomer } = usePlan();
	const { data: workspaceEnv } = useWorkspaceEnv();

	const isMarketplaceEnabled =
		IS_LOCAL_OR_DEVELOPMENT || Boolean(workspace.marketplace_enabled);

	const { data: marketplaceIntegrationSpecs } =
		useMarketplaceIntegrationSpecList({
			filters: { browsing: true },
			options: {
				enabled: isMarketplaceEnabled,
				select: (res) => res.results,
			},
		});

	const builtinIntegrationSpecsData = integrationList
		.filter(
			(integration) =>
				(!integration.hidden &&
					(workspaceEnv?.slack_enabled || integration.type !== 'slack')) ||
				(integrationV3 && integration.type === 'marketplace')
		)
		.map((integration) => ({
			group: integration.group,
			title: integration.name,
			data: { type: 'builtin', value: integration } as IntegrationSpec,
		}));

	const marketplaceIntegrationSpecsData = (marketplaceIntegrationSpecs || [])
		.filter((spec) => spec.versions.length > 0)
		.map((spec) => ({
			group: toTitleCase(spec.versions[0].category.toLowerCase()).replace(
				'_',
				' '
			),
			title: spec.versions[0].name,
			data: { type: 'marketplace', value: spec } as IntegrationSpec,
			key: spec.id,
		}));

	const data = [
		...builtinIntegrationSpecsData,
		...marketplaceIntegrationSpecsData,
	];

	const { mutateAsync: createIntegration } = useCreateIntegration({
		invalidationKeys: [integrationsQueryKeyFactory.allLists()],
	});

	const createCustomIntegration = async () => {
		if (!IS_LOCAL_OR_DEVELOPMENT && !isPayingCustomer) {
			window.open('https://feedback.secoda.co/integrations', '_blank');
			return;
		}

		const integration = await createIntegration({
			data: {
				name: 'Untitled',
				type: 'custom',
				credentials: {},
			},
		});

		navigate(`/integrations/${integration.id}/`);
	};

	const handleIntegrationSelect = async (
		widgetData: WidgetData<IntegrationSpec>
	) => {
		const { type, value } = widgetData.data;

		const handleNavigation = async () => {
			if (type === 'builtin') {
				if (value.type === 'custom') {
					await createCustomIntegration();
				} else if (value.type === 'marketplace') {
					navigate('/marketplace-integration/new');
				} else {
					navigate(`/integrations/new?type=${value.type}`);
				}
			} else {
				navigate(`/integrations/new?selectedMarketplaceSpec=${value.id}`);
			}
		};

		closeModal(NEW_INTEGRATION_MODAL_ID);
		await handleNavigation();
	};

	return (
		<WidgetSelector
			data={data}
			type="filter"
			onWidgetSelect={handleIntegrationSelect}
			widgetGroupProps={{
				classNames: {
					widgetItemsWrapper: classes.widgetItemsWrapper,
					widgetWrapper: classes.widgetWrapper,
				},
			}}
			scrollAreaHeight={theme.other.space[160]}
			cols={3}
			renderer={IntegrationSpecCard}
		/>
	);
}

export default AddIntegrationModal;
