import { IconUsers } from '@tabler/icons-react';
import { uniqBy } from 'lodash-es';
import type { UserGroup } from '../../lib/models';

import { LiteUserOut } from '@repo/api-codegen';
import type { SelectablePropertyItem } from '../SingleSelector/types';

export const getOwnerOptions = (
	users: LiteUserOut[],
	onOwnerClick: (value: any) => void,
	isSidebar = true,
	hasGroup = true
) =>
	uniqBy(users, (user) => user.id).map((owner) => {
		const item = {
			label: owner.display_name,
			value: owner.id,
			icon: owner.profile_picture,
			navigateTo: `/user/${owner.id}`,
			onClick: isSidebar ? onOwnerClick('owners') : onOwnerClick,
			strikeThrough: owner.disabled,
			hidden: owner.disabled,
		} as SelectablePropertyItem;

		if (hasGroup) {
			item.group = 'users';
		}

		return item;
	});

export const getGroupOptions = (
	groups: UserGroup[],
	onGroupClick: (value: any) => void,
	isSidebar = true,
	hasGroup = true
) =>
	uniqBy(groups, (group) => group.id).map((group) => {
		const item = {
			label: group.name,
			value: group.id,
			defaultIcon: IconUsers,
			navigateTo: `/group/${group.id}`,
			onClick: isSidebar ? onGroupClick('owners_groups') : onGroupClick,
		} as SelectablePropertyItem;

		if (hasGroup) {
			item.group = 'groups';
		}

		return item;
	});

export const getOwnerAndGroupSelectorOptions = (
	users: LiteUserOut[],
	groups: UserGroup[],
	onOwnerClick: (value: any) => void,
	onGroupClick: (value: any) => void,
	isSidebar = true
) => {
	const ownerOptions = getOwnerOptions(users, onOwnerClick, isSidebar);
	const groupOptions = getGroupOptions(groups, onGroupClick, isSidebar);

	return [...ownerOptions, ...groupOptions];
};
