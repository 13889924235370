import { Group, Input, Menu, Modal, Space, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button, IconButton } from '@repo/foundations';
import { DataTableColumn } from '@repo/mantine-datatable';
import { useCallback, useMemo, useRef } from 'react';
import { queryClient, useAuthUser, type Catalog } from '../../../api';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../constants';
import { trackEvent } from '../../../utils/analytics';
import type { ColumnName } from '../helpers';
import type { UseColumnDefsArgs } from '../hooks/useColumnDefs';

import {
	fetchApiTablePropertiesRemoveCustomProperty,
	fetchApiTablePropertiesRenameCustomProperty,
} from '@repo/api-codegen';
import { catalogQueryKeyFactory } from '../../../api/hooks/catalog/constants';
import { resourceCatalogQueryKeyFactory } from '../../../api/hooks/resourceCatalog/constants';
import { STICKY_COLUMNS } from '../../TableV2/constants';
import { ExtendedDataTableColumn } from '../../TableV2/types';
import { useColumnDefs } from '../hooks/useColumnDefs';
import MenuList from './MenuList';

interface ICustomizeColumnsPanelProps<T>
	extends Pick<UseColumnDefsArgs<T>, 'catalogType' | 'catalogServerType'> {
	defaultColumns: DataTableColumn<T>[];
	entityId?: null | string;
	onRenameCustomProperty?: (oldName: string, newName: string) => void;
	onRemoveCustomProperty?: (columnName: string) => void;
	onChangeVisibility?: (columnName: string, isVisible: boolean) => void;
	onChangeOrder?: (catalog: Catalog) => void;
}

function CustomizeColumnsPanel<T>({
	defaultColumns,
	catalogType,
	catalogServerType,
	entityId = null,
	onRenameCustomProperty,
	onRemoveCustomProperty,
	onChangeVisibility,
	onChangeOrder,
}: ICustomizeColumnsPanelProps<T>) {
	const { catalog, onColumnReorder, onColumnVisibilityChange } = useColumnDefs({
		defaultColumns,
		catalogServerType,
		catalogType,
		entityId,
	});

	const [opened, { toggle, close }] = useDisclosure(false);
	const inputRef = useRef<null | string>(null);
	const existingColumnNameRef = useRef<null | string>(null);

	const { user, workspace } = useAuthUser();

	const updateRef = useCallback((input: string) => {
		const columnName = input.replace(CUSTOM_PROPERTY_COLUMN_PREFIX, '');

		existingColumnNameRef.current = columnName;
		inputRef.current = columnName;
	}, []);

	const handleCustomPropertyRename = useCallback(async () => {
		if (!catalog || !entityId) {
			return;
		}

		if (!inputRef.current || !existingColumnNameRef.current) {
			return;
		}

		const existingColumnName = `${CUSTOM_PROPERTY_COLUMN_PREFIX}${existingColumnNameRef.current}`;
		const updatedColumnName = `${CUSTOM_PROPERTY_COLUMN_PREFIX}${inputRef.current}`;

		await fetchApiTablePropertiesRenameCustomProperty({
			pathParams: {
				entityId: entityId!,
			},
			body: {
				old_property_name: existingColumnName,
				new_property_name: updatedColumnName,
			},
		});

		onRenameCustomProperty?.(existingColumnName, updatedColumnName);
		trackEvent('catalog/customize/property/rename', {}, user, workspace);
		queryClient.invalidateQueries(catalogQueryKeyFactory.all());
		// We have to also invalidate the `resourceCatalogQueryKeyFactory` key to
		// ensure that upon renaming, the new property name has the values of the
		// old property. This is only necessary when properties are "renamed".
		queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());

		close();
	}, [catalog, close, entityId, onRenameCustomProperty, user, workspace]);

	const handleCustomPropertyDelete = useCallback(async () => {
		if (!inputRef.current || !entityId) {
			return;
		}

		const columnName = `${CUSTOM_PROPERTY_COLUMN_PREFIX}${inputRef.current}`;

		await fetchApiTablePropertiesRemoveCustomProperty({
			pathParams: {
				entityId,
			},
			body: {
				property: columnName,
			},
		});

		onRemoveCustomProperty?.(columnName);
		trackEvent('catalog/customize/property/delete', {}, user, workspace);
		queryClient.invalidateQueries(catalogQueryKeyFactory.all());

		close();
	}, [close, entityId, onRemoveCustomProperty, user, workspace]);

	const handleColumnOrderChange = useCallback(
		async (columnName: string, toIndex: number) => {
			const reorderedCatalog = await onColumnReorder(columnName, toIndex + 1);

			if (reorderedCatalog) {
				onChangeOrder?.(reorderedCatalog);
				queryClient.invalidateQueries(catalogQueryKeyFactory.all());
			}

			trackEvent('catalog/customize/reorder', {}, user, workspace);
		},
		[onChangeOrder, onColumnReorder, user, workspace]
	);

	const handleVisibilityChange = useCallback(
		async (columnName: ColumnName, isVisible: boolean) => {
			await onColumnVisibilityChange(columnName, isVisible);
			onChangeVisibility?.(columnName, isVisible);
			queryClient.invalidateQueries(catalogQueryKeyFactory.all());

			trackEvent(
				'catalog/customize/toggle_column',
				{
					column: columnName,
				},
				user,
				workspace
			);
		},
		[onChangeVisibility, onColumnVisibilityChange, user, workspace]
	);

	const handleSettingsClick = useCallback(
		(columnName: string) => () => {
			updateRef(columnName);
			toggle();
		},
		[toggle, updateRef]
	);

	const propertiesToDisplay = useMemo(
		() =>
			catalog?.properties
				.filter((p) => !STICKY_COLUMNS.includes(p.value))
				.filter(
					(p) =>
						// Ensure that the custom property is not already displayed.
						p.value.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX) ||
						defaultColumns.find(
							(c) =>
								c.accessor === p.value ||
								(c as ExtendedDataTableColumn<T>).esAccessor === p.value
						)
				) ?? [],
		[catalog?.properties, defaultColumns]
	);

	return (
		<>
			<Menu width={250} position="bottom-end" withinPortal>
				<Menu.Target>
					<Tooltip label="Rearrange columns">
						<IconButton
							data-testid="customize-columns-button"
							iconName="adjustmentsHorizontal"
							variant="tertiary"
						/>
					</Tooltip>
				</Menu.Target>
				<Menu.Dropdown>
					<MenuList
						disableDragging={false}
						catalogProperties={propertiesToDisplay}
						catalogType={catalogType}
						onColumnOrderChange={handleColumnOrderChange}
						onVisibilityChange={handleVisibilityChange}
						onSettingsClick={handleSettingsClick}
					/>
				</Menu.Dropdown>
			</Menu>
			<Modal
				title="Edit custom property"
				onClose={close}
				opened={opened}
				withCloseButton
				withinPortal
			>
				<Input
					defaultValue={inputRef.current ?? ''}
					onChange={(e) => {
						inputRef.current = e.currentTarget.value;
					}}
				/>
				<Space h={16} />
				<Group position="right">
					<Button onClick={handleCustomPropertyDelete} tone="critical">
						Delete
					</Button>
					<Button variant="primary" onClick={handleCustomPropertyRename}>
						Save
					</Button>
				</Group>
			</Modal>
		</>
	);
}

export default CustomizeColumnsPanel;
