import { Group, Skeleton, UnstyledButton, createStyles, getStylesRef } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { ISecodaEntity, IUser } from '../../api';
import { UserAvatar } from '../UserAvatar';
import { ResourcePreview } from '../ResourcePreview';
import { isUserPreview } from '../ResourcePreview/ResourcePreview';
import { SecodaEntityIcon } from '../SecodaEntity/SecodaEntityIcon';
import { useResourceLink } from './useResourceLink';

const useStyles = createStyles(
	(
		theme,
		{ isSelected }: { isSelected: boolean; }
	) => ({
		wrapper: {
			display: 'inline-block',
			verticalAlign: 'bottom',
			borderRadius: theme.radius.sm,
			border: `2px solid ${isSelected ? theme.colors.blue[5] : 'transparent'}`,

			'&:focus-within': {
				borderColor: theme.colors.blue[5],
			},
		},
		anchor: {
			ref: getStylesRef('anchor'),

			// NOTE: Do not set a `height` property here. We want the element to take up the height of its content. Fix the content height instead.
			display: 'block',
			cursor: 'pointer !important',
			textDecoration: 'none !important',
			fontSize: 'inherit', // we want the text size to match the paragraph around it
			lineHeight: 'inherit', // we want the text size to match the paragraph around it
			color: `${theme.other.getColor('text/primary/default')} !important`,
			backgroundColor: theme.other.getColor('fill/transparent-secondary/default'),
			outline: 'none !important',
			padding: `0 ${theme.spacing['2xs']} 0 ${theme.spacing['4xs']}`,
			borderRadius: theme.radius.sm,

			'&:hover': {
				backgroundColor: theme.other.getColor('fill/transparent-secondary/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('fill/transparent-secondary/active'),
			},
			
		},
		text: {
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	})
);

interface ResourceLinkProps {
	href: string | undefined | null;
	isSelected?: boolean;
}

export function ResourceLink({
	href,
	isSelected = false,
}: ResourceLinkProps) {
	const { id, data, resourceType, isLoading, hasError } = useResourceLink({
		href,
	});
	const { classes } = useStyles({ isSelected });

	if (!href) {
		return null;
	}

	if (isLoading) {
		return <Skeleton className={classes.wrapper} width={200} height={22} />;
	}

	if (hasError || !data || !id) {
		return <a href={href}>{href}</a>;
	}

	return (
		<div className={classes.wrapper}>
			<ResourcePreview
				id={id}
				data={data}
				resourceType={resourceType}
				isLoading={isLoading}
				hasError={hasError}
			>
				<UnstyledButton
					component="a"
					href={href}
					className={classes.anchor}
					data-testid="resource-link"
				>
					<Group spacing="4xs" align="center" noWrap>
						{isUserPreview(data) ? (
							<>
								<UserAvatar user={data as IUser} size="xxs" />
								<Text className={classes.text}>
									{(data as IUser).display_name}
								</Text>
							</>
						) : (
							<>
								<SecodaEntityIcon
									entity={
										data.entity_type === 'monitor' ? data.targetEntity : data
									}
									inline
									size={20}
								/>
								<Text className={classes.text}>
									{(data as ISecodaEntity).title}
								</Text>
							</>
						)}
					</Group>
				</UnstyledButton>
			</ResourcePreview>
		</div>
	);
}
