import type { DataQualityScore } from '@repo/api-codegen';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { QualityScoreRing } from '../QualityScoreRing';
import { formatScore } from '../utils';
import { DataQualityDetailsCardBody } from './DataQualityDetailsCardBody';

export function renderScore(
	scoreType: 'points' | 'percentage',
	score: number,
	max: number
) {
	if (score === -1) {
		return '-';
	}

	if (scoreType === 'points') {
		return `${formatScore(score)}/${max}`;
	}

	return `${formatScore((score / max) * 100)}%`;
}

interface DataQualityDetailsCardProps {
	data: DataQualityScore;
	direction?: 'horizontal' | 'vertical';
	scoreType?: 'points' | 'percentage';
}

export const DataQualityDetailsCard = forwardRef(
	(
		{
			data,
			direction = 'vertical',
			scoreType = 'points',
		}: DataQualityDetailsCardProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<DataQualityDetailsCardBody
			ref={ref}
			direction={direction}
			ring={
				<QualityScoreRing
					score={data.total}
					size={direction === 'vertical' ? 'md' : 'lg'}
				/>
			}
			scoreType={scoreType}
			data={data}
		/>
	)
);
