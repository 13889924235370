import { useMemo } from 'react';
import { ISecodaEntity } from '../../../api';
import MultiSelectorTarget from '../../MultiSelector/MultiSelectorTarget';
import { SecodaEntityIcon } from '../../SecodaEntity';
import { getSummaryAndBreadCrumbs } from '../../../utils/breadcrumb';

interface RelatedEntitiesSelectorProps {
	initialValue?: ISecodaEntity[];
}

export default function RelatedEntitiesSelector({
	initialValue,
}: RelatedEntitiesSelectorProps) {
	const selected = useMemo(
		() =>
			initialValue
				?.filter((relatedEntity) => !!relatedEntity)
				?.map((relatedEntity) => {
					const breadcrumbs = getSummaryAndBreadCrumbs(
						relatedEntity.native_type || relatedEntity.entity_type,
						relatedEntity.search_metadata
					);

					return {
						label: relatedEntity.title || 'Untitled',
						value: relatedEntity.id,
						icon: <SecodaEntityIcon entity={relatedEntity} size={20} />,
						labelDetails: breadcrumbs,
					};
				}) ?? [],
		[initialValue]
	);

	return (
		<MultiSelectorTarget
			selected={selected}
			iconType="react-node"
			property="entity"
			propertyLabel="Related entities"
			isMenuItemBadge={false}
			isViewerUser={false}
			permittedId={''}
			readOnly
		/>
	);
}
