import type { Filter } from '@repo/api-codegen';
import type { ReactNode } from 'react';
import type { IFilterSelection } from '../../api';
import type { FilterValue as LegacyFilterValue } from '../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';

export type FilterValueType = string | number | boolean;

export type TopLevelOperatorType = 'and' | 'or';

export enum FilterOperator {
	Is = 'is',
	IsNot = 'is not',
	Contains = 'contains',
	DoesNotContain = 'does not contain',
	isSet = 'is set',
	isNotSet = 'is not set',
	IsOnOrBefore = 'is on or before',
	IsOnOrAfter = 'is on or after',
	IsBetween = 'is between',
}

export interface FilterItem {
	label: string;
	value: FilterValueType;
	icon?: ReactNode;
	metadata?: unknown;
}

export enum FilterType {
	Single = 'single',
	Multiple = 'multiple',
}

/**
 * FROM v1: apps/frontend/src/pages/SearchPage/FilterCarousel/FilterCarousel.constants.ts#189 (FilterValue enum)
 */
export enum FilterOptionType {
	// START v1 types
	NATIVE_TYPE = 'native_type',
	INTEGRATION = 'integration_id',
	DATABASE = 'database',
	SCHEMA = 'schema',
	TAGS = 'tags',
	PUBLISHED = 'published',
	VERIFICATION = 'verified',
	PII = 'pii',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	SOURCES = 'sources',
	PARENT_ID = 'parent_id',
	RELATED = 'related',
	SLACK_CHANNELS = 'slack_channels',
	QUESTION_STATUS = 'question_status',
	QUESTION_PRIORITY = 'question_priority',
	// END v1 types
	AI = 'ai',
	TITLE = 'title',
	DESCRIPTION = 'description',
	TEAMS = 'teams',
	CREATED_TIME = 'created_at',
	UPDATED_TIME = 'updated_at',
	EXTERNALLY_UPDATED_TIME = 'external_updated_at',
	FREQUENT_USERS = 'frequent_users',
	TABLE = 'table',
	DATA_QUALITY = 'dqs.total',
	INCIDENT_STATUS = 'incident_status',
	IS_TESTED = 'is_tested',
	TESTS_PASSING = 'tests_passing',
	IS_MONITORED = 'is_monitored',
	MONITORS_PASSING = 'monitors_passing',
	// Monitoring page
	HAS_INCIDENT = 'has_incident',
	RUN_DATE = 'run_date',
}

export enum FilterDropdownType {
	List = 'list',
	String = 'string',
	Date = 'date',
	AI = 'ai',
}

export interface IFilterDropdownConfig {
	dropdownType: FilterDropdownType;
	renderMenuItem?: (item: FilterItem) => ReactNode;
	defaultOperator: FilterOperator;
	hasIsNotSetOption?: boolean;
	hasIsSetOption?: boolean;
	multipleCountsSuffix?: string;
	// eslint-disable-next-line no-use-before-define
	convertToCatalogFilter?: (value: FilterValueType | null) => Filter | null;
}

export interface FilterDropdownConfigList extends IFilterDropdownConfig {
	dropdownType: FilterDropdownType.List;
	/**
	 * Defaults to `FilterType.Multiple`
	 */
	filterType?: FilterType;
	searchPlaceholder?: string;
	getItems:
		| FilterItem[]
		| ((
				page?: number,
				searchTerm?: string
		  ) => FilterItem[] | Promise<FilterItem[]>);
	getItemsById?: (ids: string[]) => FilterItem[] | Promise<FilterItem[]>;
	disabledInNestedSearch?: boolean;
}

// Specific value types dropdowns
export interface FilterDropdownConfigString extends IFilterDropdownConfig {
	dropdownType: FilterDropdownType.String;
	inputPlaceholder?: string;
}
export interface FilterDropdownConfigDate extends IFilterDropdownConfig {
	dropdownType: FilterDropdownType.Date;
}

// UI-specific dropdowns
export interface FilterDropdownConfigAI extends IFilterDropdownConfig {
	dropdownType: FilterDropdownType.AI;
}

export interface FilterOption {
	label: string;
	type: FilterOptionType;
	field: string;
	icon?: ReactNode;
	filterDropdownConfig:
		| FilterDropdownConfigList
		| FilterDropdownConfigString
		| FilterDropdownConfigDate
		| FilterDropdownConfigAI;
}

export interface FilterValue {
	filterType: FilterOptionType;
	value: FilterValueType | FilterValueType[] | null;
	operator: FilterOperator;
	isNotSetApplied?: boolean;
	isSetApplied?: boolean;
}

export interface FilterItems {
	error: boolean;
	loading: boolean;
	items: FilterItem[];
}

export enum SortValue {
	RELEVANCE = 'relevance',
	POPULARITY = 'external_usage',
	SECODA_VIEWS = 'internal_usage',
	UPDATED_AT = 'updated_at',
	CREATED_AT = 'created_at',
}

export interface FilterView {
	label: string;
	value: string;
	filters: Partial<Record<LegacyFilterValue, IFilterSelection>>;
	isOwner: boolean;
	teams: string[];
}
