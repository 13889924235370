import type { MenuProps } from '@mantine/core';
import { Menu, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { TeamOut } from '@repo/api-codegen';
import { Icon, Text } from '@repo/foundations';
import type React from 'react';
import { useAuthUser } from '../../../api';
import { useTeamMyMembershipsLeaveJoin } from '../../../api/hooks/team/myMemberships';
import { useTeamManagement } from '../../../api/hooks/team/teamsManagement';
import { InviteTeamMemberModal } from '../../InviteTeamMemberModal/InviteTeamMemberModal';
import { TeamSettingsModal } from '../../TeamSettingsModal/TeamSettingsModal';

interface ITeamSettingMenuProps extends MenuProps {
	team: TeamOut;
	withLeaveJoinButton?: boolean;
}

// eslint-disable-next-line react/function-component-definition
export const TeamSettingMenu: React.FC<ITeamSettingMenuProps> = ({
	team,
	children,
	withLeaveJoinButton = true,
	...rest
}) => {
	const { archiveTeam, restoreTeam } = useTeamManagement(team.id);

	const { user } = useAuthUser();

	const [
		inviteTeamMemberModalOpened,
		{ close: closeInviteTeamMemberModal, open: openInviteTeamMemberModal },
	] = useDisclosure(false);

	const [
		teamSettingsModalOpened,
		{ close: closeTeamSettingsModal, open: openTeamSettingsModal },
	] = useDisclosure(false);

	const { myMemberships, apiJoinTeam, leaveTeam } =
		useTeamMyMembershipsLeaveJoin(team);

	const onClickMenuTeamSettings = (e: React.MouseEvent) => {
		e.stopPropagation();
		openTeamSettingsModal();
	};

	const onClickMenuAddMembers = (e: React.MouseEvent) => {
		e.stopPropagation();
		openInviteTeamMemberModal();
	};

	const onClickMenuArchiveTeam = (e: React.MouseEvent) => {
		e.stopPropagation();
		archiveTeam();
	};

	const onClickMenuRestoreTeam = (e: React.MouseEvent) => {
		e.stopPropagation();
		restoreTeam();
	};

	const onClickMenuLeaveTeam = (e: React.MouseEvent) => {
		e.stopPropagation();
		leaveTeam();
	};

	const onClickMenuJoinTeam = async (e: React.MouseEvent) => {
		e.stopPropagation();
		await apiJoinTeam({
			pathParams: {
				teamId: team.id,
			},
		});
	};

	const joinLeaveTeamMenuItem = () => {
		if (!myMemberships) return null;

		if (!myMemberships.length)
			return (
				<Menu.Item
					icon={<Icon name="doorEnter" />}
					onClick={onClickMenuJoinTeam}
				>
					Join team
				</Menu.Item>
			);

		if (myMemberships.length === 1 && myMemberships[0].user?.id === user.id) {
			return (
				<Menu.Item
					icon={<Icon name="doorExit" />}
					onClick={onClickMenuLeaveTeam}
				>
					Leave team
				</Menu.Item>
			);
		}

		const groups = myMemberships
			.filter((m) => m.user_group)
			.map((m) => m.user_group!.name);

		return (
			<Tooltip
				label={`Unable to leave team because you joined via ${groups.join(
					', '
				)}`}
				offset={12}
				withArrow
				multiline
				width={256}
			>
				<span>
					<Menu.Item disabled icon={<Icon name="doorExit" />}>
						Leave team
					</Menu.Item>
				</span>
			</Tooltip>
		);
	};

	const archiveRestoreTeamMenuItem = () => {
		if (team.archived) {
			return (
				<Menu.Item
					onClick={onClickMenuRestoreTeam}
					icon={<Icon name="archive" />}
				>
					<Text color="text/primary/default" size="sm">
						Restore team
					</Text>
				</Menu.Item>
			);
		}

		return (
			<Menu.Item
				onClick={onClickMenuArchiveTeam}
				icon={<Icon name="archive" color="text/critical/default" />}
			>
				<Text color="text/critical/default" size="sm">
					Archive team
				</Text>
			</Menu.Item>
		);
	};

	return (
		<>
			<Menu shadow="md" {...rest}>
				<Menu.Target>{children}</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item
						icon={<Icon name="settings" />}
						onClick={onClickMenuTeamSettings}
					>
						Team settings
					</Menu.Item>
					<Menu.Item
						onClick={onClickMenuAddMembers}
						icon={<Icon name="userPlus" />}
					>
						Add members
					</Menu.Item>
					{(withLeaveJoinButton || !team.is_default_team) && <Menu.Divider />}
					{withLeaveJoinButton && joinLeaveTeamMenuItem()}
					{!team.is_default_team && archiveRestoreTeamMenuItem()}
				</Menu.Dropdown>
			</Menu>
			<TeamSettingsModal
				opened={teamSettingsModalOpened}
				onClose={closeTeamSettingsModal}
				team={team}
			/>
			<InviteTeamMemberModal
				opened={inviteTeamMemberModalOpened}
				onClose={closeInviteTeamMemberModal}
				team={team}
				onFinish={closeInviteTeamMemberModal}
			/>
		</>
	);
};
