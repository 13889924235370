import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import type { ActionIconProps } from '@mantine/core';
import { ActionIcon, Popover, Text, createStyles } from '@mantine/core';

import type { MantineNumberSize } from '@mantine/styles';
import { useState } from 'react';

export interface IEmojiIconSelectorProps {
	icon: string;
	title?: string;
	onChange: (emoji: string) => void;
	defaultEmoji?: string;
	iconProps?: ActionIconProps;
	emojiSize?: MantineNumberSize;
}

const useStyles = createStyles({
	popover: { backgroundColor: 'transparent !important', border: 'unset' },
});

function EmojiIconSelector({
	icon,
	title,
	onChange,
	defaultEmoji = '🗂',
	iconProps = {},
	emojiSize = 'xl',
}: IEmojiIconSelectorProps) {
	const [opened, setOpened] = useState(false);
	const [emoji, setEmoji] = useState(icon ?? defaultEmoji);

	const { classes } = useStyles();

	// @ts-expect-error TS(7006): Parameter 'updatedEmoji' implicitly has an 'any' t... Remove this comment to see the full error message
	const handleSelect = (updatedEmoji) => {
		setEmoji(updatedEmoji.native);
		onChange(updatedEmoji.native);
	};

	const handleOpenPopover = () => {
		setOpened((o) => !o);
	};

	return (
		<Popover
			opened={opened}
			onChange={setOpened}
			position="bottom"
			withinPortal
		>
			<Popover.Target>
				<ActionIcon
					aria-label={title}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...iconProps}
					onClick={handleOpenPopover}
				>
					<Text style={{ fontSize: emojiSize }}>{emoji}</Text>
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown className={classes.popover} p={0}>
				<Picker
					data={data}
					button={
						<ActionIcon
							aria-label={title}
							pr={4}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...iconProps}
							onClick={handleOpenPopover}
						>
							<Text style={{ fontSize: emojiSize }}>{emoji}</Text>
						</ActionIcon>
					}
					onEmojiSelect={handleSelect}
					searchable
					size="small"
				/>
			</Popover.Dropdown>
		</Popover>
	);
}

export default EmojiIconSelector;
